import Button from "@/components/Common/Buttons/Button/Button";
import AboutButton from "@/components/Common/Buttons/AboutButton/AboutButton";
import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import BlogPostItem from "@/components/Components/BlogPostItem/BlogPostItem";
import BlogPostItemSmall from "@/components/Components/BlogPostItemSmall/BlogPostItemSmall";
import PagePreloader from "@/components/Components/PagePreloader/PagePreloader.vue";


export default {
    name: 'BlogCategoryPage',
    components: {
        PagePreloader,

        Button,
        CheckBox,
        BlogPostItem,
        BlogPostItemSmall,
        AboutButton
    },
    data() {
        return {
            isLoading: false,
            categoriesMenuActive: false,
            selectedCategory: {
                title: 'categories',
                value: 'price_ascending',
            },
            sortingCategories: [],
            translation: {
                categories: 'Категории',
                architecture: 'Архитектура',
                interior_design: 'Интериорен дизайн',
                roommate_stories: 'Съквартирантски истории',
                interesting_from_city: 'Интересно от града',
                events: 'Събития',
                top_posts: 'Топ публикации',
                last_posts: 'Последни публикации',
            },
            blog_top_posts: [],
            blog_new_posts: [],
        }
    },
    methods: {
        toggleCategoriesMenu() {
            this.categoriesMenuActive = !this.categoriesMenuActive
        },
        selectCategory(category) {
            this.toggleCategoriesMenu();
            this.selectedCategory = category;
        },
    },
    async mounted() {
        this.isLoading = true;
        const category = this.$route.params.id;
        this.sortingCategories = await this.$store.dispatch('blog/getCategories');

        if (category) {
            this.blog_new_posts = await this.$store.dispatch('blog/getCategoryPosts', {
                category: this.$route.params.id,
                limit: 5
            });
        } else {
            this.blog_new_posts = await this.$store.dispatch('blog/getNewestPosts', {
                limit: 5
            });
        }

        const excludePostIds = this.blog_new_posts.map((item)=>{return item.id;});
        this.blog_top_posts = await this.$store.dispatch('blog/getFeaturedPosts', {exclude: excludePostIds});
        this.isLoading = false;
    },
}
