import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import RadioButton from "@/components/Common/Inputs/RadioButton/RadioButton";
import PrinceRangeSlider from "@/components/Components/PriceRangeSlider/PriceRangeSlider";
import InputField from "@/components/Common/Inputs/Input/Input";
import FilterArrowDownIcon from "@/components/Common/Icons/FilterArrowDownIcon.vue";


export default {
    name: 'MarketplaceFilter',
    props: {
        title: {
            type: String,
            default: 'medium'
        },
        filtertype: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            default: null
        },
    },
    components: {
        FilterArrowDownIcon,
        CheckBox,
        RadioButton,
        PrinceRangeSlider,
        InputField
    },
    mounted() {
    },
    watch: {
        value: {
            handler(newVal, oldVal){
                const mapped = []
                Object.keys(newVal).forEach(key => {
                    if (newVal[key] == null || newVal[key] === false) {
                        return;
                    }
                    mapped.push(key);
                });

                this.$emit('update:modelValue', mapped);
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            filterBoxActive: false,
            priceFrom: 0,
            priceTo: 1000,
            value: {}
        }
    },
    methods: {
        toggleFilterBox() {
            this.filterBoxActive = !this.filterBoxActive
        },
    }
}

