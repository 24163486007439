import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import Checkbox from "@/components/Common/Inputs/CheckBox/CheckBox";

export default {
    name: 'MarketplaceSortFilter',
    props: {
    },
    components: {
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew,
        Checkbox

    },
    mounted() {
        this.sortingOptions = [
            {
                title: this.$t('price-ascending'),
                value: 'price_ascending',
            },
            {
                title: this.$t('price-descending'),
                value: 'price_descending',
            },
            {
                title: this.$t('date-added'),
                value: 'create_date',
            },
        ]
        this.selectedOption = {
            title: this.$t('price-ascending'),
            value: 'price_ascending',
        }
    },
    data() {
        return {
            filterBoxActive: false,
            selectedOption: {},
            translation: {
                sort_label: 'Подреди по',
            },
            sortingOptions: []
        }
    },
    methods: {
        toggleFilterBox() {
            this.filterBoxActive = !this.filterBoxActive
        },
        selectOption(selectedOption) {
            this.selectedOption = selectedOption;
            this.$emit('sort', selectedOption.value);
            this.filterBoxActive = false;
        },
    }
}