import Button from "@/components/Common/Buttons/Button/Button";
import InputField from "@/components/Common/Inputs/Input/Input";
import GalleryMain from "@/components/Components/GalleryMain";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import SingleCleaningIcon from "@/components/Common/Icons/SingleCleaningIcon"
import DeepCleaningIcon from "@/components/Common/Icons/DeepCleaningIcon"
import SubscriptionCleaningIcon from "@/components/Common/Icons/SubscriptionCleaningIcon"
import CleaningLocationHoursInfo from "@/components/Components/CleaningLocationHoursInfo/CleaningLocationHoursInfo";
import axios from "axios";
import Footer from "@/components/Footer/Footer";
import MovingIcon from "@/components/Common/Icons/MovingIcon.vue";
import MovingTransportationIcon from "@/components/Common/Icons/MovingTransportationIcon.vue";
import MovingHelperIcon from "@/components/Common/Icons/MovingHelperIcon.vue";

export default {
    name: 'CleaningPage',
    components: {
        MovingHelperIcon,
        MovingTransportationIcon,
        MovingIcon,
        GalleryMain,
        Button,
        InputField,
        TextArea,
        SingleCleaningIcon,
        SubscriptionCleaningIcon,
        DeepCleaningIcon,
        CleaningLocationHoursInfo,
        Footer
    },
    data() {
        return {
            serviceName: null,
            services: {
                cleaning: [
                    {
                        name: 'one-time',
                        description: 'highlight-1-description-service',
                        link: { name: 'SelectCleaningServicePage', query: {type: 'one-time'} },
                    },
                    {
                        name: 'deep-cleaning',
                        description: 'highlight-2-description-service',
                        link: { name: 'SelectCleaningServicePage', query: {type: 'deep'} },
                    },
                    {
                        name: 'subscription-cleaning',
                        description: 'highlight-3-description-service',
                        link: { name: 'SelectCleaningServicePage', query: {type: 'subscription'} },
                    }
                ],
                moving: [
                    {
                        name: 'moving-moving-title',
                        description: 'moving-moving-description',
                        link: { name: 'SelectMovingServicePage', query: {type: 'moving'} },
                    },
                    {
                        name: 'moving-transport-title',
                        description: 'moving-transport-description',
                        link: { name: 'SelectMovingServicePage', query: {type: 'transport'} },
                    },
                    {
                        name: 'moving-helper-title',
                        description: 'moving-helper-description',
                        link: { name: 'SelectMovingServicePage', query: {type: 'helper'} },
                    }
                ]
            },
            images: {
                moving: [],
                cleaning: [],
                interior: [],
            },
        }
    },
    methods: {
        translate(key) {
            return this.$t(this.serviceName + '-' + key)
        },
        scrollToServices() {
            this.$refs.servicesList.scrollIntoView({ behavior: 'smooth' });
        }
    },
    mounted() {
        switch (this.$route.name) {
            case 'MovingPage':
                this.serviceName = 'moving';
                break;
            case 'CleaningPage':
                this.serviceName = 'cleaning';
                break;
            case 'InteriorPage':
                this.serviceName = 'interior';
                break;
            default:
                this.$router.push({name: 'Index'})
                break;
        }
    },
}
