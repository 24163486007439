<script>
import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import MarketplaceAd from "@/components/Components/MarketplaceAd/MarketplaceAd";
import ProductCategory from "@/views/MarketplaceProductCategories/ProductCategory/ProductCategory";


export default {
  name: 'ProductCategoriesGrid',
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    isPromo: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CheckBox,
    MarketplaceAd,
    ProductCategory
  },
  mounted() {
  },
  data() {
    return {
      filterBoxActive: false,
    }
  },
  methods: {
    toggleFilterBox() {
      this.filterBoxActive = !this.filterBoxActive
    }
  }
}
</script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'input-field': true,
    'progress-slider-grey': theme === 'grey',
  }">
    <div class="grid-container">
      <div class="grid-products row">
        <div class="marketplace-grid-item col-md-2 col-6" v-for="category in categories">
          <div class="inner-grid-item">
            <ProductCategory :is-promo="isPromo" :categoryId="category.id" :title="category.title" :image="category.image" />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

