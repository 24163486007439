<template>


  <div class="mt-5" v-if="selectedServiceType !== 'deep'">
    <CustomerInfoForm v-model="clientInfoForm"/>
  </div>

  <div class="customer-info" v-if="selectedServiceType === 'deep'">
    <div class="stage-3-title text-center"> {{ $t('cleaning-stage-3-title') }}</div>
    <div class="stage-3-subtitle text-center">{{ $t('cleaning-stage-3-subtitle') }}</div>
    <div class="stage-3-subscription-content">
      <div class="stage-3-subscription-info-wrapper d-flex">
        <div class="me-2 stage-3-subscription-placeholder"> {{ $t('selected-plan') }}:</div>
        <div> {{ $t(selectedServiceType + '-cleaning') }}</div>
      </div>
      <div class="stage-3-subscription-info-wrapper ">
        <div class="me-2 stage-3-subscription-placeholder"> {{ $t('point-of-contact') }}:</div>
        <div> {{ $t('name') }}: {{ clientInfo.form.name }}</div>
        <div> {{ $t('phone') }}: {{ clientInfo.form.phone }}</div>
        <div> {{ $t('email') }}: {{ clientInfo.form.email }}</div>
      </div>
      <div class="stage-3-subscription-info-wrapper ">
        <div class="me-2 stage-3-subscription-placeholder"> {{ $t('additional-information') }}:</div>
        <div> {{ clientInfo.form.message }}</div>
      </div>
    </div>
  </div>

  <div class="one-vendor-cart-wrapper" v-if="selectedServiceType !== 'deep'">
    <div class="cart-item" v-for="item in $store.state.cleaning.selectedServices">
      <CleaningCartItem
        :title="$t(item.serviceKey)"
        :price="item.price"
        :quantity="item.quantity ?? null"
        :service-key="item.serviceKey"
        :sqm="item.sqm ?? null"
        :months="item.months ?? null"
        :hours="item.hours ?? null"
        :subs-type="item.subsType ?? null"
        :severity="item.hasOwnProperty('severity') ? item.severity : defaultSeverity"
        @onItemRemoved="handleItemRemoved"
      />
    </div>
    <div>
      <div class="d-flex mt-5 align-items-center">
        <div class="col-md-8 free-delivery-title"></div>
        <div class="col-md-4 breakdown-per-vendor">
          <div class="d-flex total-per-vendor">
            <div class="ms-auto total-amount"> {{ $t('total') }}:</div>
            <div class="ms-2 total-amount-per-vendor">
              {{ parseFloat($store.state.cleaning.totalPrice).toFixed(2) }} {{ $t('bgn') }}
            </div>
          </div>
        </div>
      </div>
      <div class="cart-additional-information">
        <div> {{ $t(cartAdditionalInfoPrefix + 'cart-additional-information-line-1') }}</div>
        <div> {{ $t(cartAdditionalInfoPrefix + 'cart-additional-information-line-2') }}</div>
        <div> {{ $t(cartAdditionalInfoPrefix + 'cart-additional-information-line-3') }}</div>
        <div> {{ $t(cartAdditionalInfoPrefix + 'cart-additional-information-line-4') }}</div>
        <div> {{ $t(cartAdditionalInfoPrefix + 'cart-additional-information-line-5') }}</div>
        <div> {{ $t(cartAdditionalInfoPrefix + 'cart-additional-information-line-6') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import RadioButton from "@/components/Common/Inputs/RadioButton/RadioButton";
import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import CartItem from "@/components/Components/CartItem/CartItem";
import CleaningCartItem from "@/components/Components/CleaningCartItem/CleaningCartItem";
import Input from "@/components/Common/Inputs/Input/Input";
import CustomerInfoForm from "@/views/SelectCleaningServicePage/CustomerInfoForm.vue";

export default {
  name: "SelectCleaningStage3",
  components: {
    CustomerInfoForm,
    Input,
    RadioButton,
    CheckBox,
    TextArea,
    CartItem,
    CleaningCartItem
  },
  props: {
    selectedServiceType: {
      type: String,
      default: ''
    },
    clientInfo: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      clientInfoForm: {},
      orderTotals: {
        productsAmount: 4269.90,
        deliveryAmount: 18.80,
        totalAmount: 4288.70,
      },
      order: {},
      orderValidated: null,
      formErrors: {
        name: false,
        phone: false,
        email: false,
        deliveryType: false,
        deliveryCity: false,
        deliveryAddress: false,
        isInvoiceRequired: false,
        invoiceDetails: {
          copmanyName: false,
          companyAddress: false,
          eik: false,
          accountablePerson: false,
          companyId: false,
        },
        paymentType: false,
        additionalInfo: false,
        products: false,
        productQuantities: false,
      },
      invoiceType: null,
      citiesOptions: [],
      wasFormSent: false,

      moveServices: ['moving', 'transport', 'helper'],
    }
  },
  computed: {
    defaultSeverity() {
      return this.moveServices.includes(this.selectedServiceType) ? null : 1;
    },
    cartAdditionalInfoPrefix() {
      return this.moveServices.includes(this.selectedServiceType) ? 'moving-' : '';
    }
  },
  watch: {
    clientInfoForm: {
      handler(newVal, oldVal) {
        this.$emit('update:modelValue', newVal);
      },
      deep: true,
      immediate: true
    },
  },
  async mounted() {
    this.order = this.initOrder();
    await this.$store.dispatch("location/getCities");
    this.cities = this.$store.state.location.cities ?? null;
    this.citiesOptions = this.$store.state.location.citiesOptions ?? null;
  },
  methods: {
    initOrder() {
      return {
        name: "",
        phone: "",
        email: "",
        deliveryType: null,
        deliveryCity: "",
        deliveryAddress: "",
        invoiceDetails: {
          copmanyName: "",
          companyAddress: "",
          eik: "",
          accountablePerson: "",
          companyId: "",
        },
        paymentType: null,
        additionalInfo: "",
        products: [],
        productQuantities: {},

        //local only
        isInvoiceRequired: null,

        hasAdditionalInfo: 0,
      };
    },
    handleItemRemoved() {
      if (Object.keys(this.$store.state.cleaning.selectedServices).length === 1) {
        const route = this.moveServices.includes(this.selectedServiceType) ? 'MovingPage' : 'CleaningPage';
        this.$router.push({name: route});
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";

.cart-additional-information {
  @include body-3;
  color: $color-grey-500;
  border-top: 1px solid $color-grey-400;
  margin-top: $spacing-3;
  padding: $spacing-2 0;
}

.stage-3-subscription-info-wrapper {
  border-bottom: 1px solid $color-grey-200;
  padding: $spacing-3 0;
  @include body-1;
}

.stage-3-subscription-placeholder {
  font-family: $font-bold;
}

.stage-3-title {
  margin-top: $spacing-3;
  @include subtitle-3;
  padding-bottom: $spacing-1;
}

.stage-3-subtitle {
  margin-bottom: $spacing-5;
  @include paragraph-1;
}

.wrapper-title {
  @include subtitle-3;
  border-bottom: 1px solid $color-grey-200;
  padding: $spacing-1 0;
  margin-bottom: $spacing-1;
}

.input-container {
  padding: $spacing-1 0 $spacing-4;
}

.radio-checkbox-options {
  padding: $spacing-1 0;
  margin-right: 30px;
  @include paragraph-1;
}

.option-select-label {
  padding-left: 8px;
}

.payment-methods-wrapper {
  .payment-method {
    background-color: $color-grey-100;
    border-radius: 19px;
    padding: $spacing-2;
    margin: $spacing-1 0;
    @include body-2;
  }

  .payment-subtitle {
    @include body-3;
  }
}

.one-vendor-cart-wrapper {
  background-color: $color-grey-100;
  padding: $spacing-3;
  margin: $spacing-2 0;
  border-radius: 20px;

  span {
    color: $color-orange-main;
    cursor: pointer;
    margin-left: 5px;
  }

  .conditions {
    font-family: $font-regular;
    font-size: $font-size-button-s;
    margin: $spacing-2 0;
    cursor: pointer;
  }

  .cart-item {
    margin: $spacing-2 0;
  }

  .free-delivery-title {
    font-size: 11px;
  }

  .total-amount {
    font-family: $font-bold;
  }

  .total-amount-per-vendor {
    color: $color-orange-main;
    @include title-h6;
  }
}

.input-info {
  font-size: 11px;
  width: 10px;
  padding-left: 5px;
}

.input-container {
  text-align: center;
}

.input-item {
  margin: $spacing-1 0;
}

.text-area, .file-input {
  height: 15rem;
  padding: 5px;
}


</style>
