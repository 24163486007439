<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <Modal :name="'creditBuy'"
         :is-open="$store.state.utils.modals.creditBuy"
         :title="$t('credit-buy-modal-title')"
         :show-footer="true"
  >
    <div class="credit-buy-description w-100 d-flex justify-content-center" v-if="false">
      {{ $t('credit-buy-modal-description') }}
    </div>

    <div class="selection-ad-container row g-0"
         v-for="(credit, index) in credits"
         :key="'credits-' + index"
         v-bind:class="{'gold-background svg-container': credit.promo}"
         @click="select(credit.credits)">
      <div class="col-1 checkbox">
        <input type="radio" v-bind:checked="selected === credit.credits">
      </div>
      <div class="text-container ps-4 col col-md-11">
        <div class="d-flex svg-container">
          <div class="d-flex svg-container" v-bind:class="{'white-background': credit.promo}">
          <div class="me-2">
            <CoinsIcon/>
          </div>
          <div class="selection-subtitle">
            {{ credit.credits }} {{ $t('credits') }}
          </div></div>
          <div class="g-0 ms-auto selection-subtitle d-flex">
            <div class="me-3" v-if="credit.promo">
              <span class="badge text-uppercase" style="background-color: #FF7A00">{{ $t('promo') }}</span>
            </div>
            {{ credit.price }} {{ $t('currency') }}
          </div>
        </div>
      </div>
      <div class="bckg-img" v-if="credit.promo">
        <svg width="228" height="120" viewBox="0 0 228 86" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M47.1556 51.0175C78.0391 51.0175 93.3465 34.6358 93.3465 6.97501V-1.35009C93.3465 -29.0109 78.3076 -45.124 47.1556 -45.124C16.5407 -45.124 0.964692 -29.0109 0.964692 -1.35009V6.97501C0.964692 34.6358 16.2721 51.0175 47.1556 51.0175ZM51.1839 134H74.5479C76.1592 134 77.5019 133.194 78.3076 131.852L128.527 45.3779L179.015 -38.4102C180.357 -40.8272 179.552 -42.17 176.866 -42.17H153.234C151.622 -42.17 150.548 -41.3643 149.474 -40.0215L102.477 41.0811L49.0355 130.24C47.6927 132.389 48.4984 134 51.1839 134ZM47.1556 27.3849C32.6538 27.3849 26.4771 20.9397 26.4771 6.43791V-0.812992C26.4771 -15.3148 32.9224 -21.76 47.1556 -21.76C61.6574 -21.76 67.8341 -15.5833 67.8341 -0.812992V6.43791C67.8341 20.9397 61.6574 27.3849 47.1556 27.3849ZM180.626 137.223C211.241 137.223 227.085 121.11 227.085 93.4487V85.1236C227.085 57.4627 211.509 41.0811 180.626 41.0811C150.011 41.0811 134.435 57.4627 134.435 85.1236V93.4487C134.435 121.11 149.742 137.223 180.626 137.223ZM180.626 113.859C166.124 113.859 159.947 107.682 159.947 92.9116V85.6607C159.947 71.1589 166.124 64.7136 180.626 64.7136C195.396 64.7136 201.573 71.1589 201.573 85.6607V92.9116C201.573 107.413 195.128 113.859 180.626 113.859Z" fill="#1A2128" fill-opacity="0.04"/>
        </svg>
      </div>
    </div>

    <div class="credit-buy-vat-description">
      {{ $t('credit-buy-vat-price-description') }}
    </div>
    <div class="button-proceed" @click="pay()">
      <Button :text="$t('proceed')" :theme="selected ? 'solid-green' : 'solid-grey'" :size="'medium'"/>
    </div>
  </Modal>

</template>