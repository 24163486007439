<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div id="main-container" class="container" v-if="property">
    <GalleryMain :images="property.images" v-if="property.images.length > 0"/>
    <div class="row after-gallery parent" ref="parent">
      <div class="col-md-8 left">
        <div class="property-title-id ">
          <div class="pet-friendly d-flex mb-3 align-items-center" v-if="property.isPetFriendly" >
            <PawIcon/>
            <div class="ms-2"> {{ $t('pet-friendly')}} </div>
          </div>
          <SinglePropertyTitleID :title="property.title" :propertyid="property.id"/>

        </div>
        <div class="col-md-4 contact-container d-md-none">
          <PropertyAdContact :user="property.hasOwnProperty('user') ? property.user : null"
                             :agency="property.hasOwnProperty('agency') ? property.agency : null"
                             :amount="property.price"
                             :property="property"
                             :date="property.createdAt"
                             :views="'424'"/>
        </div>
        <div class="property-specs-container mt-4">
          <SinglePropertyNavigation :has-advantages="property.propertyAdvantages.length > 0" :has-location="property.gpsCoordinates && property.gpsCoordinates.long !== null && property.gpsCoordinates.lat !== null"/>
          <SinglePropertyDescription :description="property.description" id="description"/>
          <SinglePropertySpecification id="features" v-if="propertySettings.type"
                                       :propertytype="$t('property-type-' + property.type)"
                                       :constructiontype="$t('property-construction-' + property.construction)"
                                       :bedroomnumber="$t('property-bedrooms-' + property.bedrooms)"
                                       :bathroomnumber="$t('property-bathrooms-' + property.bathrooms)"
                                       :squaresize="property.areaSqm"
                                       :constructionyear="property.buildingYear"
                                       :heatingtype="$t('property-heating-' + property.heating)"
                                       :furnishment="$t('property-furniture-' + property.furniture)"
                                       :floor="property.floor"
                                       :totalfloors="property.totalFloors"/>
          <SinglePropertyAmenities :amenities="property.appliances" :all-amenities="allAmenities"/>


          <div class="features-main-title" v-if="property.propertyAdvantages.length > 0">{{ $t('property-features-title') }}</div>
          <div id="advantages">
            <SinglePropertyFeatures  v-for="propertyAdvantage in property.propertyAdvantages"
                                     :title="propertyAdvantage.title" :description="propertyAdvantage.description"/>
          </div>

          <SinglePropertyUtilities :lowrangetotal="billsFrom"
                                   :highrangetotal="billsTo"
                                   :lowrangeelectro="property.billsMeta.electricity"
                                   :lowrangeheating="property.billsMeta.heating"
                                   :lowrangewater="property.billsMeta.water"
                                   :lowrangecomunal="property.billsMeta.maintenance"
                                   :description="property.taxDescription"/>
          <SinglePropertyLocation id="location" v-if="property.gpsCoordinates && property.gpsCoordinates.long !== null && property.gpsCoordinates.lat !== null"
                                  :lat="parseFloat(property.gpsCoordinates.long)"
                                  :lng="parseFloat(property.gpsCoordinates.lat)"
                                  :description="property.locationAdvantages"/>
        </div>
      </div>
      <div class="col-md-4 right-column d-none d-md-block" ref="rightColumn">
        <PropertyAdContact :user="property.hasOwnProperty('user') ? property.user : null"
                           :agency="property.hasOwnProperty('agency') ? property.agency : null"
                           :amount="property.price"
                           :date="property.createdAt"
                           :property="property"
                           :views="'424'"/>
      </div>
    </div>
  </div>
  <div class="container" v-if="products && morePropertiesSameCity && morePropertiesSameVendor">
    <SinglePropertyMarketplaceCarousel
        v-if="missingAmenityProducts.length > 0"
        :products="missingAmenityProducts"
        :missing-amenities="propertyMissingAmenities"
    />


    <div class="row mt-5">
      <div class="col-md-6">
        <SinglePropertyAdCarousel :properties="morePropertiesSameCity"
                                  :title="$t('more-properties-from') + ' ' + $t(property.city.name)"/>
      </div>
      <div class="col-md-6">
        <SinglePropertyAdCarousel :properties="morePropertiesSameVendor"
                                  :title="$t('more-properties-from') + ' ' + (property.hasOwnProperty('agency') ? property.agency.name : property.user.name)"/>
      </div>
    </div>
  </div>
  <div v-else>
    <PreLoader :size="'small'"/>
  </div>

</template>
