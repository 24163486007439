<template>
  <vue-countdown :time="secondsLeft * 1000" v-slot="{ days, hours, minutes, seconds }">
    <div class="d-flex">
      <div class="countdown-wrapper">
        <div class="numbers-container">{{ pad(days) }}</div>
        <div>{{ $t('days') }}</div>
      </div>
      <div class="countdown-wrapper">
        <div class="numbers-container">{{ pad(hours) }}</div>
        <div>{{ $t('hours') }}</div>
      </div>
      <div class="countdown-wrapper">
        <div class="numbers-container">{{ pad(minutes) }}</div>
        <div>{{ $t('minutes') }}</div>
      </div>
      <div class="countdown-wrapper">
        <div class="numbers-container">{{ pad(seconds) }}</div>
        <div>{{ $t('seconds') }}</div>
      </div>
    </div>
  </vue-countdown>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const targetDate = new Date('2024-12-31T23:59:59Z');

const secondsLeft = ref(0);

function calculateSecondsLeft() {
  const now = new Date();
  const differenceInMilliseconds = targetDate.getTime() - now.getTime();
  return Math.max(Math.floor(differenceInMilliseconds / 1000), 0); // Ensure non-negative
}

onMounted(() => {
  secondsLeft.value = calculateSecondsLeft();
});

const pad = (value) => value.toString().padStart(2, '0');
</script>
<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";
.countdown-wrapper {
  margin: 0 5px;
  text-align: center;
  color: #f1f1f1;
  .numbers-container {
    font-size: 50px;
    font-family: $font-bold;
    line-height: 1;
    color: #E7111A;
    border-radius: 20px;
    padding: 10px;
    width: 75px;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(234,234,234,1) 50%);
  }
}
@media screen and (min-width: 450px) {
  .countdown-wrapper {
    .numbers-container {
      width: 100px;
      font-size: 55px;
      padding: 20px;
    }
  }
}
@media screen and (min-width: 768px) {
  .countdown-wrapper {
    .numbers-container {
      width: 120px;
      font-size: 69px;
      padding: 20px;
    }
  }
}
</style>