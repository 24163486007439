import Button from '@/components/Common/Buttons/Button/Button';
import InputField from '@/components/Common/Inputs/Input/Input';
import xIcon from '@/components/Common/Icons/xIcon';
import FileInput from '@/components/Common/Inputs/FileInput/FileInput';
import TextArea from '@/components/Common/Inputs/TextArea/TextArea';
import Dropdown from '@/components/Common/Inputs/Dropdown/Dropdown';
import CheckBox from '@/components/Common/Inputs/CheckBox/CheckBox';
import YesNoInput from '@/components/Common/Inputs/YesNoInput/YesNoInput';
import MakePremium from '@/components/Components/MakePremium/MakePremium/';
import PremiumAdSelection from '@/components/Components/PremiumAdSelection/PremiumAdSelection/';
import CreditWallet from '@/components/Components/CreditWallet/CreditWallet/';
import AdUpdate from '@/components/Components/AdUpdate/AdUpdate';
import UserTypeMobile from '@/components/Header/HeaderMobile/UserTypeMobile/UserTypeMobile';
import UserNavMobile from '@/components/Header/HeaderMobile/UserNavMobile/UserNavMobile';
import FileService from '@/services/FileService';
import PagePreloader from "@/components/Components/PagePreloader/PagePreloader.vue";


export default {
    name: 'AdminAddProperty',
    components: {
        PagePreloader,
        Button,
        CheckBox,
        xIcon,
        MakePremium,
        AdUpdate,
        PremiumAdSelection,
        CreditWallet,
        TextArea,
        Dropdown,
        InputField,
        UserTypeMobile,
        UserNavMobile,
        YesNoInput,
        FileInput,
    },
    data() {
        return {
            isLoading: false,
            propertySettings: null,
            isSidebarStatic: false,
            propertyAdvantagesForms: [{title: '', description: ''}],
            numberOptions: {0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6},
            yearOptions: {},
            propertyAppliances: [],
            property: {
                price: null,
                deposit: null,
                district_id: null,
                title: null,
                description: null,
                taxDescription: null,
                type: null,
                construction: null,
                heating: null,
                furniture: null,
                floor: null,
                totalFloors: null,
                bedrooms: null,
                bathrooms: null,
                areaSqm: null,
                billsMeta: {electricity: '', heating: '', water: '', maintenance: '', description: ''},
                locationAdvantages: '',
                propertyAdvantages: [],
                buildingYear: null,
                gpsCoordinates: '',
                images: [],
                status: 0,
                isVisible: true,
                isPetFriendly: false,
                appliances: [],
            },
            formErrors: [],
            categoriesMenuActive: false,

            cities: null,
            //Holds the cities mapped as {name: id}
            citiesMapped: null,
            //Holds the selected city id, so it can be used to get the districts
            propertyLocationCity: null,
            //Holds the districts for the selected city
            propertyLocationDistricts: {},

            selectedPlan: 'regular'
        };
    },
    watch: {
        propertyLocationCity: async function (cityId, oldVal) {
            this.propertyLocationDistricts = await this.$store.dispatch("location/getDistrictsMapped", cityId);
        }
    },
    async mounted() {
        let vm = this;
        const id = this.$route.params.id;

        const currentYear = (new Date()).getFullYear();
        for (let i = 1900; i < currentYear; i++) {
            this.yearOptions[i] = i;
        }

        if (this.$store.state.user.info.type === 'vendor') {
            this.$router.push({name: 'VendorDashboard'});
            return;
        }

        this.isLoading = true;
        this.cities = await this.$store.dispatch("location/getCities");
        this.citiesMapped = await this.$store.dispatch("location/getCitiesMapped");

        if (id) {
            try {
                await this.loadProperty(id);
            } catch (err) {
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'error', message: err.message
                });
                return;
            }
        }

        if (!Object.keys(this.$store.state.property.settings).length) {
            await this.$store.dispatch('property/getSettings');
        }

        this.propertySettings = this.$store.state.property.settings ?? null;

        this.propertyAppliances = await this.$store.dispatch('property/getPropertyAppliences');

        this.propertyAppliances.forEach((p, index) => {
            if (this.property.appliances.some(pa => pa.id === p.id)) {
                p.value1 = true;
            } else p.value1 = false;
        });

        this.isLoading = false;
    },
    methods: {
        async loadProperty(id) {
            this.property = await this.$store.dispatch('property/getUserProperty', {id: id});
            this.property.isVisible = this.property.status === 1;
            this.property.isPetFriendly = this.property.isPetFriendly === true;
            this.propertyAdvantagesForms = this.property.propertyAdvantages;
            this.propertyLocationCity = await this.$store.dispatch('location/getCityIdByDistrictId', this.property.district.id);
            this.property.district_id = this.property.district.id;
        },
        selectPlan(plan) {
            let hasEnoughCredits = false;
            const userCredits = this.$store.state.user.info.credits;
            switch (plan) {
                case 'regular':
                    hasEnoughCredits = true;
                    break;
                case 'premium-7':
                    hasEnoughCredits = userCredits >= 4;
                    break;
                case 'premium-30':
                    hasEnoughCredits = userCredits >= 9;
                    break;
                case 'premium-30-plus':
                    hasEnoughCredits = userCredits >= 35;
                    break;
            }
            if (hasEnoughCredits) {
                this.selectedPlan = plan;
            } else {
                this.$store.dispatch('utils/openNotificationModal', {
                    message: this.$t('you-have-no-enough-credits-for-selected-plan'),
                    type: 'error'
                });
            }
        },
        async saveProperty() {

            if (!this.validateForm()) {
                await this.$store.dispatch('utils/openNotificationModal', {
                    message: this.$t('error-please-fulfill-all-fields-with-valid-data'),
                    type: 'error'
                });
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                return;
            }

            this.property.status = this.property.isVisible ? 1 : 0;
            this.property.petFriendly = this.property.isPetFriendly;
            this.property.gpsCoordinates = this.gpsCoordinatesString.replace(/"/g, '');
            this.property.propertyAdvantages = [];
            this.propertyAdvantagesForms.forEach(form => {
                if (form.title && form.description) {
                    this.property.propertyAdvantages.push({
                        title: form.title,
                        description: form.description,
                    });
                }
            });
            this.property.appliances = Array.from(this.currentPropertyAppliances);
            this.$forceUpdate(this.property);

            await this.$store.dispatch('utils/openNotificationModal', {
                message: "Запазване на имота ...",
                type: 'loading'
            });

            this.property.plan = this.selectedPlan;

            try {
                const property = await this.$store.dispatch('property/createProperty', this.property);
                this.$router.push({name: 'EditProperty', params: {id: property.id}})
                await this.loadProperty(property.id);
                if (this.selectedPlan !== 'regular') {
                    await this.$store.dispatch("user/refreshAuthenticatedUser");
                }
                await this.$store.dispatch('utils/openNotificationModal', {
                    message: this.$t('changes-saved-success-message'),
                    type: 'success'
                });
            } catch (err) {
                await this.$store.dispatch('utils/openNotificationModal', {
                    message: this.$t('error-try-again'),
                    type: 'error'
                });
            }
        },
        async uploadPhoto(event) {
            const files = event.target.files;
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                formData.append('file[]', file, file.name);
            }
            const images = await FileService.uploadFile(formData);
            this.property.images = this.property.images.concat(images);
        },
        removeImage(index) {
            this.property.images.splice(index, 1);
        },
        addPropertyAdvantageField(title, description) {
            if (!title || !description) {
                return;
            }
            this.propertyAdvantagesForms.push({title: '', description: ''});
        },
        switchAppliance(value, index) {
            this.propertyAppliances[index].value1 = value;
            this.$forceUpdate(this.propertyAppliances);
        },
        validateForm() {
            let vm = this;
            this.formErrors = [];

            const defaultValidationFields = Object.keys(this.property);

            defaultValidationFields.forEach(field => {
                if (!['propertyAdvantages', 'taxDescription', 'appliances', 'vipEndDate', 'isPetFriendly'].includes(field)) {
                    if (vm.property[field] === null || vm.property[field].length === 0) {
                        vm.formErrors.push(field);
                    }
                }
            });

            if (this.property.images.length === 0) {
                vm.formErrors.push('images');
            }

            if (this.property.price <= 0 || this.property.price >= 9999) {
                vm.formErrors.push('price');
            }

            if (this.property.deposit <= 0 || this.property.deposit >= 99999) {
                vm.formErrors.push('deposit');
            }

            if (this.property.areaSqm <= 0 || this.property.areaSqm > 1000) {
                vm.formErrors.push('areaSqm');
            }

            if (parseInt(this.property.floor) > parseInt(this.property.totalFloors)) {
                vm.formErrors.push('floor');
                vm.formErrors.push('totalFloors');
            }

            if (this.propertyLocationCity === null) {
                vm.formErrors.push('propertyLocationCity');
            }

            return this.formErrors.length === 0;
        },
        isValidNumber(value) {
            return value !== null && value !== '' && !isNaN(value);
        },
    },
    computed: {
        user() {
            return this.$store.state.user.info;
        },
        currentPropertyAppliances() {
            let current = [];
            this.propertyAppliances.forEach(p => {
                if (p.value1 === true) {
                    current.push(p.id);
                }
            });
            return current;
        },
        totalBills() {
            const electricity = this.isValidNumber(this.property.billsMeta.electricity) ? this.property.billsMeta.electricity : 0;
            const water = this.isValidNumber(this.property.billsMeta.water) ? this.property.billsMeta.water : 0;
            const heating = this.isValidNumber(this.property.billsMeta.heating) ? this.property.billsMeta.heating : 0;
            const maintenance = this.isValidNumber(this.property.billsMeta.maintenance) ? this.property.billsMeta.maintenance : 0;

            const total = parseFloat(electricity) + parseFloat(water) + parseFloat(heating) + parseFloat(maintenance);

            return {
                from: parseFloat(total * 0.8).toFixed(2),
                to: parseFloat(total * 1.2).toFixed(2),
            }
        },
        gpsCoordinatesString: {
            get() {
                if (typeof this.property.gpsCoordinates === 'object' && this.property.gpsCoordinates !== null) {
                    return `${this.property.gpsCoordinates.long},${this.property.gpsCoordinates.lat}`;
                } else {
                    return this.property.gpsCoordinates;
                }
            },
            set(newValue) {
                this.property.gpsCoordinates = newValue;
            },
        },
    },
};
