import Button from "@/components/Common/Buttons/Button/Button";
import ModalCloseBtn from "../ModalCloseBtn";
import InputField from '@/components/Common/Inputs/Input/Input';
import TextArea from '@/components/Common/Inputs/TextArea/TextArea';
import CheckBox from '@/components/Common/Inputs/CheckBox/CheckBox';
import Modal from './../Modal';

export default {
    name: 'RoommateOfferMessageModal',
    components: {
        Button,
        ModalCloseBtn,
        InputField,
        TextArea,
        Modal,
        CheckBox
    },
    data() {
        return {
            form: this.initForm(),
            fieldErrors: []
        }
    },
    methods: {
        initForm() {
          return {
              name: null,
              phone: null,
              message: null,
          }
        },
        async sendMessage() {
            if (this.validateInputs() === false) {
                return;
            }

            const offerId = this.$route.params.id;
            if (!offerId) {
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'error',
                    message: this.$t('error-try-again')
                });
                return;
            }

            const request = this.form;
            request.id = offerId;

            try {
                await this.$store.dispatch('utils/openNotificationModal', {type: 'loading'});
                await this.$store.dispatch("roommate/sendOfferMessage", request);
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'success',
                    message: this.$t('message-sent-successfully')
                });
            } catch (e) {
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'error',
                    message: this.$t('error-try-again')
                });
            } finally {
                await this.$store.dispatch('utils/closeModal', 'loading');
                await this.$store.dispatch('utils/closeModal', 'roommateOfferMessage');
                this.form = this.initForm();
            }
        },
        validateInputs() {
            this.fieldErrors = [];
            if (this.form.name === null || this.form.name === '' || this.form.name.length < 2) {
                this.fieldErrors.push('name');
            }
            if (this.form.phone === null || this.form.phone === '') {
                this.fieldErrors.push('phone');
            }
            if (this.form.message === null || this.form.message === '' || this.form.message.length < 3) {
                this.fieldErrors.push('message');
            }

            return this.fieldErrors.length === 0;
        }
    }
}