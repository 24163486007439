<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="buy-now-container row">
      <div class="regular-price d-flex justify-content-center" v-if="product.hasOwnProperty('promoPrice') && parseFloat(product.promoPrice) > 0">
        {{ Number.isInteger(parseFloat(product.price)) ? product.price : parseFloat(product.price).toFixed(2) }} {{ $t('currency') }}
        <LabelDiscountGreen class="ms-1" :product="product"/>
      </div>

      <div class="discounted-price">
        {{ product.hasOwnProperty('promoPrice') && parseFloat(product.promoPrice) > 0
          ? (Number.isInteger(parseFloat(product.promoPrice)) ? parseFloat(product.promoPrice) : parseFloat(product.promoPrice).toFixed(2))
          : (Number.isInteger(parseFloat(product.price)) ? parseFloat(product.price) : parseFloat(product.price).toFixed(2))
        }}
        <span>{{ $t('currency') }}</span>
      </div>

      <Button :text="$t('buy-now')" :svg="'bag'" :theme="'solid-orange'" :size="'medium'" @click="addToCart()"/>
      <div class="add-favorites d-flex justify-content-center">
        <AddToFavourites ref="favorites" size="small" class="pe-2" :product="{
            id: product.id,
            image: product.mainImage.src,
            labeldiscount: promoPrice !== null,
            title: product.title,
            category: product.categories[0].title,
            labelnew: false,
            regularamount: product.price,
            discountedamount: product.promoPrice,
        }"/>
        <span @click="$refs.favorites.toggleFavourites()">{{ $t('add-to-favorites') }}</span>
      </div>
    </div>
  </div>
</template>
