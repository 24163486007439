<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="container">
    <div class="system-pages-title">
      {{ $t('privacy-policy') }}
    </div>
    <div class="system-pages-content" v-if="$root.$i18n.locale !== 'en'">
      <p style="text-align: center;"><strong><u><span style="font-size:12pt;">Политика за защита на личните данни в sakvartirantite.com</span></u></strong>
      </p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">Данни на администратора и координати за връзка&nbsp;</span></u></strong>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">&bdquo;СЪКВАРТИРАНТИТЕ&quot; ООД вписано в Търговския регистър под ЕИК 206489692, със седалище и адрес на управление: гр. София 1408, район Триадица, квартал &bdquo;Стрелбище&ldquo; ул.Нишава 131, вх.А, ап.14, наричано по-нататък за краткост&nbsp;</span><u><span
          style="font-size:12pt;">sakvartirantite или Ние/Нас</span></u><span style="font-size:12pt;">&nbsp;е администратор на лични данни и отговаря за спазването на разпоредбите на Общия Регламент за Защита на Личните Данни 2016/679. Ние обработваме Вашите данни при пълно съответствие с Регламент (ЕС) 2016/679 (&bdquo;Общият регламент на ЕС относно защитата на данните&ldquo; или &bdquo;ОРЗД&ldquo;).</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">&nbsp;Целта на настоящата Политика за Поверителност е да ви информираме какви лични данни обработваме Ние и за какви цели, на кого ги предоставя, какви са вашите права относно обработваните ваши лични данни и как можете да ги упражните.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Запазваме си правото периодично да актуализираме и изменяме настоящата Политика за поверителност, за да отразяваме всички изменения на начина, по който обработваме личните Ви данни или измененията на вашите законови изисквания. В случай на такива изменения, ние публикуваме изменената версия на Политиката за поверителност на нашия уебсайт и поради това, любезно ви молим периодично да проверявате съдържанието ѝ.</span>
      </p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">Цели на обработване</span></u></strong>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Целта на събирането и обработването на данни е предлагането на услуги и инструменти, където потребителите да могат да използват пълната функционалност на sakvartirantite.com, включително но не само да се регистрират и да създадат профил, да публикуват или да разглеждат списъци с онлайн обяви, да установяват връзка с останалите потребители, да си разменят съобщения и да осъществяват множеството свързани функции на сайта sakvartirantite.com.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Личните данни са информация, която служи за Вашата идентификация необходима, за да може да използвате пълната функционалност на sakvartirantite.com , т.е. име, адрес, телефон , електронна поща или вписване с &nbsp;facebook &nbsp;или друга социална плтформа. &bdquo;СЪКВАРТИРАНТИТЕ&quot; ООД, под никаква форма няма да събира Ваши лични данни, освен необходимите за извършване на действия по &nbsp;уеб сайта, sakvartirantite.com.</span>
      </p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">Задължително ли е предоставянето на лични данни</span></u></strong>
      </p>
      <p style="text-align: justify;"><span
          style="font-size:12pt;">Задължителната информация, за да се регистрирате</span><span style="font-size:11pt;">&nbsp;</span><span
          style="font-size:12pt;">и да създадат профил е eлектронната ви поща, а при публикуване на обява и телефон.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Предоставянето на личните ви данни в останалата функционална част на сайта е напълно доброволно по ваше желание.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Физическото лице търсещо или предлагащо чрез онлайн обяви в sakvartirantite.com самостоятелно преценява дали и какви данни да предостави за себе си.</span>
      </p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">Лични данни които ще се обработват от sakvartirantite</span></u></strong>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Услугите, които предоставя сайта sakvartirantite.com предполагат предоставяне на Лични данни като име, адрес, телефон , електронна поща или вписване с &nbsp;facebook &nbsp;или друга социална плтформа. &nbsp;</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">В случаи, че изберете регистрация чрез Вашия профил във Facebook, ще бъдете насочени към страницата Facebook Admin, където ще бъдете уведомени за предаването на Вашите данни на sakvartirantite. Можете да разгледате политиките за поверителност на Facebook като последвате следната връзка:</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">https://www.facebook.com/about/privacy</span></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">sakvartirantite.com не събира данни с категория специални данни по смисъла на чл. 9 и 10 от Регламент (ЕС) 2016/679 . С това съгласие вие се задължавате да не съхранявате и изпращате такива данни чрез сайта, а именно и не само;</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">- данни, разкриващи Вашия расов или етнически произход, политически възгледи, религиозни или философски убеждения, членство в синдикални организации, нито Ваши генетични данни, биометрични данни, данни за здравословното Ви състояние, за сексуалния Ви живот или сексуалната Ви ориентация, или данни, свързани с Ваши присъди и нарушения.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">- документи за самоличност, както и ЕГН, номер на паспорт/лична карта и др. подобни.</span>
      </p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">Не се събират и обработват ненужни за дейността лични данни</span></u></strong>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">sakvartirantite не събира и не обработва лични данни на физически лица, които надхвърлят неговите задължения по закон или неговите нужди за правене на бизнес.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Събрани лични данни се обработват за други цели само след съгласие на лицата.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Във всички случаи, когато е необходимо събрани и обработвани лични данни на физически лица да се използват за цели различни от първоначалните, sakvartirantite уведомява съответните физически лица, иска тяхното съгласие и пристъпва към обработка на техните лични данни за други цели, само след тяхното изрично съгласие.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Предоставянето на личните ви данни в останалата функционална част на сайта е напълно доброволно по ваше желание.</span>
      </p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">Ние ще използваме Вашите лични данни за следните цели:</span></u></strong>
      </p>
      <p style="text-align: justify;"><span
          style="font-size:12pt;">&nbsp;Предоставяне на услуги на sakvartirantite</span></p>
      <p style="text-align: justify;"><span
          style="font-size:12pt;">Което може да включва, при необходимост, следното:</span></p>
      <ul>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">обработване на поръчки, включващо приемане, валидиране, експедиране и фактуриране на същите;</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">изпълнение на задължения по договор или преддоговорни отношения или за целите на изпълнение на нормативно задължение;</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">осчетоводяване на извършваните от Дружеството финансови операции;</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">за водене на кореспонденция;</span></p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">разрешаване на проблеми, свързани с анулирания на поръчки или всякакви други проблеми, свързани с поръчките, закупените стоки или услуги;</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">връщане на продуктите в съответствие със законовите разпоредби;</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">възстановяване на стойността на продуктите съгласно законовите разпоредби;</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">другите случаи, предвидени в Регламента.</span>
          </p>
        </li>
      </ul>
      <p style="text-align: justify;"><span style="font-size:12pt;">Обработването на Вашите данни за тези цели в повечето случаи е необходимо за сключването и изпълнението на договор между sakvartirantite и Вас. Освен това, за изпълнението на тези цели се изисква обработване съгласно приложимото законодателство, включително данъчното и счетоводното законодателство.</span>
      </p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">Използване на &bdquo;бисквитки&ldquo;(&lsquo;cookies&rsquo;)</span></u></strong>
      </p>
      <p><br></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Като много други уеб-сайтове, така и нашият използва бисквитки. &bdquo;Бисквитките&ldquo; са малки обособени пакети информация, изпращана от дадена организация към Вашия компютър, за да Ви разпознае при Ваше посещение. Те събират статистически данни за действията Ви в браузъра. Това ни позволява да проследим модели на потребителския трафик, както и да разработваме статистически анализи за използването на услугите, като времето, прекарвано на уеб-сайта и страниците, което се посещават най-често. Бисквитките не Ви идентифицират като личност, а обобщените статистически данни не включват лична информация. Бисквитките ни помагат да подобрим уеб-сайта и да предоставяме по-добра персонализирана услуга.</span>
      </p>
      <p><br></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Съгласявайки се да използвате този сайт и услугите му, Вие изразявате съгласие за използването на &bdquo;бисквитки&ldquo;, включително бисквитките на Google Analytics.</span>
      </p>
      <p><br></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Ако предпочитате да не получавате &bdquo;бисквитки&ldquo; докато разглеждате Уебсайта или чрез HTML форматирани имейли, бихте могли да ги откажете. За целта можете да настроите интернет браузъра си да предупреждава преди да приеме бисквитка или да откажете бисквитки, когато Ви сигнализира за наличието им. Обикновено можете да намерите тези настройки в меню &lsquo;Опции&rsquo; или &lsquo;Предпочитания&rsquo; на вашия браузър. За да сте наясно с тези настройки, както и за по-подробна информация, можете да използвате бутона &lsquo;Помощ&rsquo; от менютата на Вашия браузър.</span>
      </p>
      <p style="text-align: justify;"><strong><u><span
          style="font-size:12pt;">Информация за правата на субекта на данни</span></u></strong></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Общият регламент относно защитата на данните признава редица права във връзка с Вашите лични данни.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">- sakvartirantite.com ви осигурява непрекъснат 24 часов достъп до вашия профил, освен в случаите на техническа неизправност.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">- Чрез достъпа до вашия профил, вие имате право на проверка и промяна на предоставената от вас информация.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">- Имате възможност и да се откажете от съгласието за обработване и да изтриете данните ви чрез закриване на профила Ви.</span>
      </p>
      <p style="text-align: justify;"><u><span style="font-size:12pt;">Моля, имайте предвид, че преди да упражните това право, вие трябва да изтеглите от профила си в sakvartirantite.com и да запазите всички документи, свързани с направените поръчки от sakvartirantite.com, независимо дали фактурирането е било извършено към Вас или към друго физическо или юридическо лице (като фактури, удостоверения за гаранция). Ако не направите това преди да упражните правото си на изтриване, ще изгубите всички тези документи и sakvartirantite.com няма да може да ви ги предостави, в зависимост от случая, тъй като процесът на изтриване на данни и изтриването на профила в sakvartirantite.com с всички данни и документи, свързани с него, е необратим процес.</span></u>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">- Вие имате право да възразите срещу обработването на личните ви данни, което се основава на задача от обществен интерес, упражняване на официално правомощие или на легитимен интерес.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">- Правото на информираност за действия в резултат на искане за коригиране, изтриване или ограничаване на обработването на лични данни.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">- Право на преносимост на данните.</span></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">- Право да не са обект на автоматично вземане на решение, включващо профилиране.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">- Право на жалба до надзорния орган.</span></p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">Защита на нашите законни интереси</span></u></strong>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Възможно е да има случаи, в които ние използваме или предаваме информация, за да защитим правата си и търговската си дейност. Те могат да включват:</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">мерки за защита на уебсайта и потребителите на платформата на sakvartirantite срещу кибератаки;</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">мерки за предотвратяване и откриване на опити за измама, включително предаване на информация на компетентни публични органи;</span>
      </p>
      <p style="text-align: justify;"><span
          style="font-size:12pt;">мерки за управление на различни други рискове.</span></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Главната причина за тези видове обработване са нашите законни интереси, свързани със защитата на търговската ни дейност като се прави уговорка, че ние гарантираме, че всички предприемани от нас мерки гарантират баланс между нашите интереси и Вашите основни права и свободи.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Освен това, в някои случаи, обработването от наша страна се базира на законови разпоредби като задължението за защита на стоките и стойностите, предвидено от приложимото законодателство в тази връзка.</span>
      </p>
      <p style="text-align: justify;"><strong><u><span
          style="font-size:12pt;">Колко време пазим Вашите лични данни</span></u></strong></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">По правило, Ние съхраняваме Вашите лични данни докато имате регистрация в sakvartirantite. Вие винаги може да поискате от нас да изтрием определена информация или да закрием профила Ви и ние ще отговорим на това искане като запазим определена информация, дори и след закриване на профила, когато приложимото законодателство или законни интереси го налагат.</span>
      </p>
      <ul>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">за срок от 5 години &ndash; когато личните данни са получени за целите на изпълнение на договори или преддоговорни отношения. &nbsp;Срокът започва да тече от датата на изпълнение на договора;</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">&nbsp;за нормативно определен срок &ndash; ако личните данни се обработват на основание изпълнение на нормативно задължение от sakvartirantite;</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">до поискване от физическо лицето за тяхното изтриване.</span>
          </p>
        </li>
      </ul>
      <p style="text-align: justify;"><span
          style="font-size:12pt;">след което те се унищожават без излишно забавяне.</span></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Във всички случаи sakvartirantite осигурява поне веднъж годишно да се прави преглед на събираните и обработваните лични данни и тези от тях, които попадат в някоя от горните хипотези се изтриват без излишно забавяне.&nbsp;</span>
      </p>
      <p style="text-align: justify;"><strong><u><span
          style="font-size:12pt;">Принципи при обработка на лични данни</span></u></strong><span
          style="font-size:12pt;">&nbsp;</span></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">&nbsp;Ние считаме гарантирането на правото на защита на личните данни за основен ангажимент обработваме Вашите данни при пълно съответствие с Регламент (ЕС) 2016/679 (&bdquo;Общият регламент на ЕС относно защитата на данните&ldquo; или &bdquo;ОРЗД&ldquo;) и всяко друго приложимо законодателство.&nbsp;</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">sakvartirantite събира и обработва лични данни законосъобразно, добросъвестно и в съответствие с принципите и правата на физическите лица във връзка с обработването на техните лични данни.</span>
      </p>
      <p style="text-align: justify;"><strong><u><span
          style="font-size:12pt;">Личните данни се обработват прозрачно</span></u></strong></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">sakvartirantite.com осигурява прозрачност в комуникацията за събираните и обработваните лични данни като информацията за това е в кратка, прозрачна, разбираема и лесно достъпна форма, и се използват ясни и недвусмислени формулировки.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">&nbsp;</span></p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">Обработвани лични данни са точни и актуални</span></u></strong>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">sakvartirantite.com осигурява обработването личните данни на физически лица да се извършва с максимална точност и по възможност винаги в актуалност.&nbsp;</span>
      </p>
      <p><br></p>
      <p style="text-align: justify;"><strong><u><span
          style="font-size:12pt;">Правила за обработка на лични данни</span></u></strong></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Личните данни се обработват с необходимите нива и мерки за защита.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Личните данни се обработват от минимум необходимия брой лица.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">sakvartirantite осигурява достъпът и обработката на личните данни на физически лица да се извършва от минимално необходимия брой лица (оператори), които имат нужната компетентност за тяхната обработка и нужната ангажираност за тяхното опазване.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">sakvartirantite осигурява необходимите нива на физическа, организационна и технологична защита с оглед на:</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">1. естеството, обхвата, контекста и целта на обработваните лични данни;</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">2. вероятността, нивата на въздействие и тежестта на риска за правата и свободите на физическите лица, в случай на нарушаване на сигурността на обработваните лични данни;</span>
      </p>
      <p style="text-align: justify;"><span
          style="font-size:12pt;">3. своите финансови и организационни възможности.</span></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">sakvartirantite осигурява и всички необходими мерки за своевременно възстановяване на събирани и обработени лични данни при тяхна загуба в резултат на случайни, злонамерени или форсмажорни събития.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Личните данни се обработват с контролиран и проследим достъп</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">sakvartirantite осигурява необходимите и подходящи технически, организационни и технологични мерки за контролиран и проследим достъп до личните данни на физически лица.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Личните данни се обработват с нужната отчетност за спазване на Регламента</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">sakvartirantite осигурява необходимата отчетност и регистри, за да е в състояние да докаже, че разпоредбите на Регламента са спазени.</span>
      </p>
      <p><br></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">&nbsp;&nbsp;</span></p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">Получатели и категории получатели на Вашите лични данни</span></u></strong>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">В зависимост от случая, можем да предаваме или даваме достъп до някои от Вашите лични данни на следните категории получатели:</span>
      </p>
      <ul>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">лицензирани куриерски компании, с цел доставка на закупените продукти;</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">обработващ личните данни, с цел издаване на счетоводни документи (фактури, касови бележки и др.);</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">&nbsp;счетоводна къща, с цел осчетоводяване на стопански операции.</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">партньори в пазарната платформа на sakvartirantite;</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">доставчици на платежни/ банкови услуги.</span>
          </p>
        </li>
      </ul>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">ДАННИ ЗА КОНТАКТ С ДРУЖЕСТВОТО</span></u></strong>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Ако имате въпроси или неясноти относно обработването на личните ви данни или желаете да упражните някое от своите права, можете да се свържете с нас на:</span>
      </p>
      <p style="text-align: justify;"><span
          style="font-size:12pt;">&bull; &nbsp; &nbsp;email: info@sakvartirantite.com</span></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">&bull; &nbsp; &nbsp;адрес: гр. София 1408, район Триадица, квартал &bdquo;Стрелбище&ldquo; ул.Нишава 131, вх.А, ап.14</span>
      </p>
      <p><br></p>
      <p style="text-align: justify;"><strong><u><span
          style="font-size:12pt;">КОМПЕТЕНТЕН НАДЗОРЕН ОРГАН</span></u></strong></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Комисията за защита на личните данни (КЗЛД) е независимият държавен орган, който осъществява защитата на лицата при обработването на техните лични данни и при осъществяването на достъпа до тези данни, както и контрола по спазването на Закона за защита на личните данни на територията на Република България.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">В случай на съмнение, че правата Ви, свързани със защитата на личните Ви данни, са нарушени, можете да подадете сигнал до КЗЛД на:</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">&bull; &nbsp; &nbsp;Адрес: гр. София 1592, бул. &bdquo;Проф. Цветан Лазаров&rdquo; № 2</span>
      </p>
      <p style="text-align: justify;"><span
          style="font-size:12pt;">&bull; &nbsp; &nbsp;Електронна поща: kzld@cpdp.bg</span></p>
      <p style="text-align: justify;"><span
          style="font-size:12pt;">&bull; &nbsp; &nbsp;Интернет страница: www.cpdp.bg</span></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">&bull; &nbsp; &nbsp;Телефон: 02 / 91-53-518</span>
      </p>
    </div>
    <div v-if="$root.$i18n.locale !== 'bg'">
      <p style="text-align: center;"><strong><u><span style="font-size:12pt;">Privacy Policy for sakvartirantite.com</span></u></strong>
      </p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">Administrator Data and Contact Details</span></u></strong>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">"SAKVARTIRANTITE" Ltd. registered in the Commercial Register under UIC 206489692, with headquarters and management address: Sofia 1408, Triaditza district, "Strelbishte" quarter, 131 Nishava Street, Entrance A, Apt.14, hereinafter referred to as </span><u><span style="font-size:12pt;">sakvartirantite or We/Us</span></u><span style="font-size:12pt;"> is a personal data administrator and is responsible for compliance with the provisions of the General Data Protection Regulation 2016/679. We process your data in full compliance with Regulation (EU) 2016/679 ("EU General Data Protection Regulation" or "GDPR").</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">The purpose of this Privacy Policy is to inform you what personal data we process and for what purposes, to whom we provide it, what your rights are regarding your processed personal data and how you can exercise them.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">We reserve the right to periodically update and modify this Privacy Policy to reflect any changes in the way we process your personal data or changes in your legal requirements. In case of such changes, we publish the modified version of the Privacy Policy on our website, and therefore, we kindly ask you to periodically check its content.</span>
      </p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">Processing Purposes</span></u></strong>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">The purpose of collecting and processing data is to provide services and tools where users can use the full functionality of sakvartirantite.com, including but not limited to registering and creating a profile, publishing or viewing online listings, connecting with other users, exchanging messages, and performing the many related functions of the sakvartirantite.com website.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Personal data is information that serves for your identification necessary to use the full functionality of sakvartirantite.com, i.e., name, address, phone, email, or registration with Facebook or other social platforms. "SAKVARTIRANTITE" Ltd. will not collect your personal data in any form except as necessary to perform actions on the website, sakvartirantite.com.</span>
      </p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">Is the Provision of Personal Data Mandatory</span></u></strong>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">The mandatory information to register and create a profile is your email, and when publishing an ad, also your phone number.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Providing your personal data in the remaining functional part of the site is completely voluntary at your discretion.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">The individual seeking or offering through online ads in sakvartirantite.com independently decides whether and what data to provide about themselves.</span>
      </p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">Personal Data to be Processed by sakvartirantite</span></u></strong>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">The services provided by sakvartirantite.com involve the provision of Personal Data such as name, address, phone, email, or registration with Facebook or other social platforms.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">If you choose to register through your Facebook profile, you will be directed to the Facebook Admin page where you will be notified of the transfer of your data to sakvartirantite. You can review Facebook's privacy policies by following this link:</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">https://www.facebook.com/about/privacy</span></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">sakvartirantite.com does not collect special category data as defined in Articles 9 and 10 of Regulation (EU) 2016/679. By this consent, you agree not to store and transmit such data through the site, including but not limited to:</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">- data revealing your racial or ethnic origin, political views, religious or philosophical beliefs, trade union membership, or your genetic data, biometric data, data concerning your health, your sex life or sexual orientation, or data relating to your convictions and violations.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">- identity documents, as well as personal identification numbers, passport/ID card numbers, etc.</span>
      </p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">No Unnecessary Personal Data is Collected or Processed</span></u></strong>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">sakvartirantite does not collect or process personal data of individuals that exceed its legal obligations or its business needs.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Collected personal data is processed for other purposes only with the consent of the individuals.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">In all cases where it is necessary to use collected and processed personal data for purposes different from the original ones, sakvartirantite notifies the respective individuals, requests their consent, and proceeds with processing their personal data for other purposes only after their explicit consent.</span>
      </p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">We Will Use Your Personal Data for the Following Purposes:</span></u></strong>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Provision of sakvartirantite services</span></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Which may include, when necessary, the following:</span></p>
      <ul>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">processing orders, including acceptance, validation, shipping, and invoicing of the same;</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">fulfillment of contractual obligations or pre-contractual relations or for the purposes of fulfilling regulatory obligations;</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">accounting for financial operations performed by the Company;</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">for correspondence;</span></p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">resolving problems related to order cancellations or any other issues related to orders, purchased goods, or services;</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">product returns in accordance with legal provisions;</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">refunding product value in accordance with legal provisions;</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">other cases provided for in the Regulation.</span>
          </p>
        </li>
      </ul>
      <p style="text-align: justify;"><span style="font-size:12pt;">The processing of your data for these purposes is in most cases necessary for concluding and performing a contract between sakvartirantite and you. Additionally, processing is required for compliance with applicable legislation, including tax and accounting legislation.</span>
      </p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">Use of Cookies</span></u></strong>
      </p>
      <p><br></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Like many other websites, ours uses cookies. "Cookies" are small separate packages of information sent by an organization to your computer to recognize you during your visits. They collect statistical data about your browser actions. This allows us to track patterns of user traffic and develop statistical analyses of service usage, such as time spent on the website and the most frequently visited pages. Cookies do not identify you as an individual, and the aggregated statistical data does not include personal information. Cookies help us improve the website and provide better personalized service.</span>
      </p>
      <p><br></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">By agreeing to use this site and its services, you consent to the use of cookies, including Google Analytics cookies.</span>
      </p>
      <p><br></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">If you prefer not to receive cookies while browsing the Website or through HTML-formatted emails, you can decline them. To do this, you can set your internet browser to warn you before accepting cookies or to refuse cookies when it alerts you to their presence. You can usually find these settings in the 'Options' or 'Preferences' menu of your browser. To understand these settings, as well as for more detailed information, you can use the 'Help' button from your browser's menus.</span>
      </p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">Information About Data Subject Rights</span></u></strong></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">The General Data Protection Regulation recognizes several rights concerning your personal data.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">- sakvartirantite.com provides you with continuous 24-hour access to your profile, except in cases of technical malfunction.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">- Through access to your profile, you have the right to verify and modify the information you have provided.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">- You also have the option to withdraw consent for processing and delete your data by closing your profile.</span>
      </p>
      <p style="text-align: justify;"><u><span style="font-size:12pt;">Please note that before exercising this right, you must download from your profile in sakvartirantite.com and save all documents related to orders made from sakvartirantite.com, regardless of whether the billing was made to you or to another natural or legal person (such as invoices, warranty certificates). If you do not do this before exercising your right to deletion, you will lose all these documents and sakvartirantite.com will not be able to provide them to you, as the case may be, since the process of data deletion and profile deletion in sakvartirantite.com with all related data and documents is irreversible.</span></u>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">- You have the right to object to the processing of your personal data which is based on a task of public interest, exercise of official authority, or legitimate interest.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">- The right to be informed of actions resulting from requests for correction, deletion, or restriction of personal data processing.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">- Right to data portability.</span></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">- Right not to be subject to automated decision-making, including profiling.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">- Right to complain to the supervisory authority.</span></p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">Protection of Our Legitimate Interests</span></u></strong>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">There may be cases where we use or transfer information to protect our rights and business operations. These may include:</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">measures to protect the website and users of the sakvartirantite platform against cyber attacks;</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">measures to prevent and detect fraud attempts, including transferring information to competent public authorities;</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">measures to manage various other risks.</span></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">The main reason for these types of processing is our legitimate interests related to protecting our business operations, with the stipulation that we guarantee that all measures we take ensure a balance between our interests and your fundamental rights and freedoms.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Additionally, in some cases, our processing is based on legal provisions such as the obligation to protect goods and values provided for by applicable legislation in this regard.</span>
      </p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">How Long We Keep Your Personal Data</span></u></strong></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">As a rule, We store your personal data as long as you have a registration with sakvartirantite. You can always request us to delete certain information or close your profile, and we will respond to this request while retaining certain information, even after profile closure, when applicable legislation or legitimate interests require it.</span>
      </p>
      <ul>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">for a period of 5 years - when personal data is obtained for the purpose of executing contracts or pre-contractual relationships. The period starts from the date of contract execution;</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">for a legally defined period - if personal data is processed based on compliance with a regulatory obligation by sakvartirantite;</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">until requested for deletion by the individual.</span>
          </p>
        </li>
      </ul>
      <p style="text-align: justify;"><span
          style="font-size:12pt;">after which they are destroyed without undue delay.</span></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">In all cases, sakvartirantite ensures that at least once a year, collected and processed personal data is reviewed, and those that fall under any of the above scenarios are deleted without undue delay.</span>
      </p>
      <p style="text-align: justify;"><strong><u><span
          style="font-size:12pt;">Principles of Personal Data Processing</span></u></strong><span
          style="font-size:12pt;"> </span></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">We consider guaranteeing the right to personal data protection a fundamental commitment and process your data in full compliance with Regulation (EU) 2016/679 ("EU General Data Protection Regulation" or "GDPR") and any other applicable legislation.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">sakvartirantite collects and processes personal data lawfully, fairly, and in accordance with the principles and rights of individuals regarding the processing of their personal data.</span>
      </p>
      <p style="text-align: justify;"><strong><u><span
          style="font-size:12pt;">Personal Data is Processed Transparently</span></u></strong></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">sakvartirantite.com ensures transparency in communication about collected and processed personal data, with information provided in a concise, transparent, understandable, and easily accessible form, using clear and unambiguous language.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;"> </span></p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">Processed Personal Data is Accurate and Up-to-date</span></u></strong>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">sakvartirantite.com ensures that the processing of individuals' personal data is carried out with maximum accuracy and, whenever possible, kept up-to-date.</span>
      </p>
      <p><br></p>
      <p style="text-align: justify;"><strong><u><span
          style="font-size:12pt;">Rules for Personal Data Processing</span></u></strong></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Personal data is processed with necessary levels and protection measures.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Personal data is processed by the minimum necessary number of people.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">sakvartirantite ensures that access to and processing of individuals' personal data is carried out by the minimum necessary number of persons (operators) who have the necessary competence for processing and the required commitment to protecting it.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">sakvartirantite provides the necessary levels of physical, organizational, and technological protection considering:</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">1. the nature, scope, context, and purpose of the processed personal data;</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">2. the probability, impact levels, and severity of risk to the rights and freedoms of individuals in case of security breaches of processed personal data;</span>
      </p>
      <p style="text-align: justify;"><span
          style="font-size:12pt;">3. its financial and organizational capabilities.</span></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">sakvartirantite provides all necessary measures for timely recovery of collected and processed personal data in case of loss due to accidental, malicious, or force majeure events.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Personal data is processed with controlled and traceable access</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">sakvartirantite provides necessary and appropriate technical, organizational, and technological measures for controlled and traceable access to individuals' personal data.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Personal data is processed with necessary accountability for compliance with the Regulation</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">sakvartirantite provides necessary accountability and registers to be able to prove that the provisions of the Regulation are followed.</span>
      </p>
      <p><br></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">  </span></p>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">Recipients and Categories of Recipients of Your Personal Data</span></u></strong>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">Depending on the case, we may transfer or give access to some of your personal data to the following categories of recipients:</span>
      </p>
      <ul>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">licensed courier companies, for delivery of purchased products;</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">personal data processor, for issuing accounting documents (invoices, receipts, etc.);</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">accounting firm, for accounting of business operations.</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">partners in the sakvartirantite market platform;</span>
          </p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
          <p style="text-align: justify;"><span style="font-size:12pt;">payment/banking service providers.</span>
          </p>
        </li>
      </ul>
      <p style="text-align: justify;"><strong><u><span style="font-size:12pt;">COMPANY CONTACT INFORMATION</span></u></strong>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">If you have any questions or concerns about the processing of your personal data or wish to exercise any of your rights, you can contact us at:</span>
      </p>
      <p style="text-align: justify;"><span
          style="font-size:12pt;">• &nbsp; &nbsp;email: info@sakvartirantite.com</span></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">• &nbsp; &nbsp;address: Sofia 1408, Triaditsa district, "Strelbishte" quarter, 131 Nishava Street, Entrance A, Apt.14</span>
      </p>
      <p><br></p>
      <p style="text-align: justify;"><strong><u><span
          style="font-size:12pt;">COMPETENT SUPERVISORY AUTHORITY</span></u></strong></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">The Commission for Personal Data Protection (CPDP) is the independent state authority that provides protection of individuals in the processing of their personal data and in accessing this data, as well as controls compliance with the Personal Data Protection Act on the territory of the Republic of Bulgaria.</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">If you suspect that your rights related to the protection of your personal data have been violated, you can file a report to CPDP at:</span>
      </p>
      <p style="text-align: justify;"><span style="font-size:12pt;">• &nbsp; &nbsp;Address: Sofia 1592, 2 Prof. Tsvetan Lazarov Blvd.</span>
      </p>
      <p style="text-align: justify;"><span
          style="font-size:12pt;">• &nbsp; &nbsp;Email: kzld@cpdp.bg</span></p>
      <p style="text-align: justify;"><span
          style="font-size:12pt;">• &nbsp; &nbsp;Website: www.cpdp.bg</span></p>
      <p style="text-align: justify;"><span style="font-size:12pt;">• &nbsp; &nbsp;Phone: 02 / 91-53-518</span>
      </p>
    </div>
  </div>


</template>
