<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="marketplace-sort-filter-container"  v-bind:class="{ 'open-filter-box': filterBoxActive }">
      <div class=" align-items-center search-results-nav-desktop">
        <div class=" d-md-flex" >
          <div class="sort-by me-1">{{ $t('sort-by') }}:</div>
          <div class="d-flex align-items-center sort-by-button me-3">
            <div class="me-1" @click="filterBoxActive = !filterBoxActive"> {{ selectedOption.title }}</div>
            <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.66675 1.16667L4.66675 11.8333" stroke="#FF7A00" stroke-linecap="round"/>
              <path d="M0.666749 7.83334L4.66675 11.8333L8.66675 7.83334" stroke="#FF7A00" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <div class="sort-filter-modal ">
          <div class="sort-item" v-for="option in sortingOptions" @click="selectOption(option)"> {{ option.title }}</div>
        </div>
      </div>
    </div>

  </div>

</template>

