import Button from '@/components/Common/Buttons/Button/Button';
import SearchResultsNav from '@/components/Components/SearchResultsNav/SearchResultsNav';
import RoommateAd from '@/components/Components/RoommateAd/RoommateAd';
import AdvancedSearchModal from '@/components/Components/AdvancedSearchModal/AdvancedSearchModal';
import Dropdown from '@/components/Common/Inputs/Dropdown/Dropdown';
import Pagination from '@/components/Components/Pagination/Pagination';
import InputField from '@/components/Common/Inputs/Input/Input';
import GalleryMain from '@/components/Components/GalleryMain/GalleryMain';
import SinglePropertyTitleID from '@/views/SinglePropertyPage/SinglePropertyTitleID/SinglePropertyTitleID';
import SinglePropertySpecification
	from '@/views/SinglePropertyPage/SinglePropertySpecification/SinglePropertySpecification';
import SinglePropertyDescription from '@/views/SinglePropertyPage/SinglePropertyDescription/SinglePropertyDescription';
import SinglePropertyNavigation from '@/views/SinglePropertyPage/SinglePropertyNavigation/SinglePropertyNavigation';
import SinglePropertyAmenities from '@/views/SinglePropertyPage/SinglePropertyAmenities/SinglePropertyAmenities';
import SinglePropertyFeatures from '@/views/SinglePropertyPage/SinglePropertyFeatures/SinglePropertyFeatures';
import SinglePropertyUtilities from '@/views/SinglePropertyPage/SinglePropertyUtilities/SinglePropertyUtilities';
import SinglePropertyLocation from '@/views/SinglePropertyPage/SinglePropertyLocation/SinglePropertyLocation';
import SinglePropertyMarketplaceCarousel
	from '@/views/SinglePropertyPage/SinglePropertyMarketplaceCarousel/SinglePropertyMarketplaceCarousel';
import PropertyAdContact from '@/components/Components/PropertyAdContact/PropertyAdContact';
import SinglePropertyAdCarousel from '@/views/SinglePropertyPage/SinglePropertyAdCarousel/SinglePropertyAdCarousel';
import DateInput from '@/components/Common/Inputs/DateInput/DateInput';
import PreLoader from '@/components/Common/PreLoader';
import PawIcon from "@/components/Common/Icons/PawIcon.vue";

export default {
    name: 'SinglePropertyPage',
    components: {
        PawIcon,
        Button,
        SearchResultsNav,
        RoommateAd,
        AdvancedSearchModal,
        Dropdown,
        Pagination,
        InputField,
        GalleryMain,
        SinglePropertyTitleID,
        SinglePropertySpecification,
        SinglePropertyDescription,
        SinglePropertyNavigation,
        SinglePropertyAmenities,
        SinglePropertyFeatures,
        SinglePropertyUtilities,
        SinglePropertyLocation,
        SinglePropertyMarketplaceCarousel,
        PropertyAdContact,
        SinglePropertyAdCarousel,
        DateInput,
        PreLoader
    },
    data() {
        return {
            loaded: false,

            metaTitlePrefix: 'dasdasd.bg - ',
            property: null,
            propertySettings: {},
            page: {},
            products: [],
            clientName: '',
            clientEmail: '',
            clientPhone: '',
            viewDate: '',
            viewTime: '',
            viewRequest: {
                name: '',
                email: '',
                phone: '',
                date: '',
                time: '',
            },
            components: {
                aboutIntro: {
                    component: 'Preview',
                },
            },
            morePropertiesSameCity: null,
            morePropertiesSameVendor: null,

            //amenities
            allAmenities: [],
            propertyMissingAmenities: [],
            missingAmenityProducts: [],
        };
    },
    computed: {
        billsFrom() {
            const bills = this.property.billsMeta;
            const total = parseFloat(parseFloat(bills.electricity) + parseFloat(bills.heating) + parseFloat(bills.water) + parseFloat(bills.maintenance))
            return parseFloat(total * 0.8).toFixed(0);
        },
        billsTo() {
            const bills = this.property.billsMeta;
            const total = parseFloat(parseFloat(bills.electricity) + parseFloat(bills.heating) + parseFloat(bills.water) + parseFloat(bills.maintenance))
            return parseFloat(total * 1.2).toFixed(0);
        },
    },
    async mounted() {
        const id = this.$route.params.id;
        if (!this.$store.state.property.list[id]) {
            await this.$store.dispatch('property/getProperty', {id: id});
        }
        this.property = this.$store.state.property.list[id] ?? null;
        if (!Object.keys(this.$store.state.property.settings).length) {
            await this.$store.dispatch('property/getSettings');
        }
        this.propertySettings = this.$store.state.property.settings ?? null;

        this.setMetaTags();

        const result = await this.$store.dispatch('product/searchProduct', {limit: 10});
        this.products = result.data;

        this.morePropertiesSameCity = await this.$store.dispatch('property/getProperties', {
            params: {
                city_id: this.property.city.id,
                exclude: [this.$route.params.id]
            },
            limit: 10
        });

        await this.loadAmenities();
        await this.loadMorePropertiesFromSameUser();


        // Sticky
        /*
        this.rightColumnInitialTop = this.$refs.rightColumn.getBoundingClientRect().top;
        this.rightColumnInitialLeft = this.$refs.rightColumn.getBoundingClientRect().left;
        this.rightColumnInitialHeight = this.$refs.rightColumn.getBoundingClientRect().height;
        this.rightColumnInitialWidth = this.$refs.rightColumn.getBoundingClientRect().width;
        window.addEventListener('scroll', () => {
            // get the current scroll position
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            // get the position and size of the parent element
            const parentRect = this.$refs.rightColumn.parentElement.getBoundingClientRect();

            // check if the scroll position is greater than the initial top position of the right column
            // and if the bottom of the right column is not yet reached
            if (
                scrollTop > this.rightColumnInitialTop &&
                scrollTop + this.rightColumnInitialHeight < parentRect.bottom
            ) {
                // apply the fixed position
                this.$refs.rightColumn.style.position = 'fixed';
                this.$refs.rightColumn.style.top = '0';
                this.$refs.rightColumn.style.left = `${this.rightColumnInitialLeft}px`;
            } else {
                // remove the fixed position
                this.$refs.rightColumn.style.position = 'static';
            }
        });

         */
    },
    methods: {
        setMetaTags() {
            const ogTitle = document.querySelector('meta[property="og:title"]');
            ogTitle.setAttribute("content", this.property.title);
        },
        async loadAmenities() {
            this.allAmenities = await this.$store.dispatch('property/getPropertyAppliences');

            let vm = this;
            const propertyAmenitiesIdList = this.property.appliances.map(amenity => amenity.id);
            this.allAmenities.forEach((amenity) => {
                if (!propertyAmenitiesIdList.includes(amenity.id)) {
                    vm.propertyMissingAmenities.push(amenity)
                }
            });

            const missingAmenityCategories = [];
            this.propertyMissingAmenities.forEach((item) => {
                missingAmenityCategories.push(item.categoryId);
            });

            if (missingAmenityCategories.length > 0) {
                const products = await this.$store.dispatch('product/searchProduct', {
                    limit: 10,
                    request: {categoryIds: missingAmenityCategories}
                });

                this.missingAmenityProducts = products.data;
            }
        },
        async loadMorePropertiesFromSameUser() {
            const params = {
                exclude: [this.$route.params.id]
            };

            if (this.property.hasOwnProperty('agency')) {
                params['agency_id'] = this.property.agency.id;
            } else {
                params['user_id'] = this.property.user.id;
            }

            this.morePropertiesSameVendor = await this.$store.dispatch('property/getProperties', {
                params: params,
                limit: 10
            });
        }
    },
};
