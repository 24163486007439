<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'property-ad': true,
    'ad-type-premium': adtype === 'premium',
    'ad-selected': isSelected,
  }">
    <div class="property-ad-container">
      <div class="thumb-frame">
        <PremiumLabelProperty class="premium-label" v-if="adtype === 'premium'"/>
        <div
            v-if="images.length > 0"
            v-bind:class="{'thumb-image': true, 'thumb-image-selected': isSelected}"
            v-bind:style="{'background-image': 'url(/storage/' + images[0].src + ')'}
          ">
          <router-link :to="{name: 'SinglePropertyPage', params: {id: id}}">
            <span class="pe-2">{{ $t('view') }}</span>
            <svg width="26" height="9" viewBox="0 0 26 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M25.3536 4.85355C25.5488 4.65829 25.5488 4.34171 25.3536 4.14645L22.1716 0.964466C21.9763 0.769204 21.6597 0.769204 21.4645 0.964466C21.2692 1.15973 21.2692 1.47631 21.4645 1.67157L24.2929 4.5L21.4645 7.32843C21.2692 7.52369 21.2692 7.84027 21.4645 8.03553C21.6597 8.2308 21.9763 8.2308 22.1716 8.03553L25.3536 4.85355ZM0 5H25V4H0V5Z"
                  fill="white"/>
            </svg>
          </router-link>

        </div>
      </div>
      <div class="fav-icon">
        <AddToFavourites :property="{
              id: id,
              adtype: adtype,
              isSelected: isSelected,
              title: title,
              images: images,
              city: city,
              district: district,
              amount: amount,
              description: description,
            }"/>
      </div>
      <div class="pet-friendly" v-if="property.isPetFriendly">
        <PawIcon/>
      </div>
      <div class="property-ad-text">
        <div class="property-info-item d-flex align-items-center">
          <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.98323 3.03555C5.97699 3.6081 5.83027 4.14936 5.60238 4.66872C5.30269 5.35702 4.89374 5.97963 4.4286 6.56469C4.04775 7.04338 3.63568 7.4939 3.18615 7.91002C3.05191 8.03516 2.93016 8.03516 2.79593 7.91002C1.95305 7.11846 1.2132 6.23931 0.651285 5.22249C0.37345 4.7219 0.164293 4.19316 0.0550315 3.62687C-0.244656 2.02499 0.707477 0.513849 2.23401 0.100865C3.85732 -0.337149 5.54931 0.695311 5.90519 2.34725C5.95514 2.57251 5.97074 2.80403 6.00508 3.03243C5.99572 3.03243 5.98948 3.03243 5.98323 3.03555ZM4.65649 2.99488C4.65025 2.07505 3.89166 1.32417 2.98323 1.33356C2.06544 1.34295 1.31934 2.10008 1.3287 3.01365C1.33807 3.93035 2.09041 4.67497 2.99572 4.67185C3.91351 4.66246 4.66273 3.90532 4.65649 2.99488Z"
                fill="#FF7A00"/>
          </svg>
          <span class="ms-1 property-text-item">
            {{ city }}, {{ district }}
          </span>
        </div>
        <div class="property-ad-title">
          <router-link :to="{name: 'SinglePropertyPage', params: {id: id}}">
            {{ titleFormatted }}</router-link>
        </div>
        <div class="property-price-tag">
            <span class="price-amount">
              {{ amount }}
            </span>
          <span class="ps-4">{{ $t('bgn-per-month') }}</span>
        </div>
      </div>

    </div>
  </div>
</template>

