<script>
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import LabelDiscount from "@/components/Common/Labels/LabelDiscount/LabelDiscount";

export default {
  name: 'ProductCategory',
  props: {
    categoryId: {
      type: Number,
      default: null
    },
    image: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    isPromo: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    routeTo() {
      const route = {name: 'MarketplaceCategoryPage', params: {id: this.$props.categoryId}};
      if (this.$props.isPromo) {
        route.query = {promo: 1};
      }
      return route;
    }
  },
  components: {
    LabelNew,
    LabelDiscount
  },
  mounted() {
  }
}
</script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{'marketplace-adv': true}">
    <div class="marketplace-ad-container ">
     <router-link :to="routeTo" class="marketplace-ad-title">
      <div class="thumb-image" v-bind:style="{'background-image': 'url(/static/' + title + '.jpg' + ')'}">
      </div>
      <div class="marketplace-ad-text">{{ $t(title) }}</div>
     </router-link>
    </div>
  </div>
</template>

