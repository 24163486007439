<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>

  <Modal :name="'roommateOfferMessage'"
         :is-open="$store.state.utils.modals.roommateOfferMessage"
         :title="$t('send-request')"
  >
    <div style="width: 420px; max-width: 100%">
      <div class="mb-3">
        <InputField :label="$t('your-name')" inputtype="text" v-model="form.name" :content="form.name"
                    :error="fieldErrors.includes('name')"/>
      </div>
      <div class="mb-3">
        <InputField :label="$t('your-phone')" inputtype="text" v-model="form.phone" :content="form.phone"
                    :error="fieldErrors.includes('phone')"
        />
      </div>
      <div class="mb-4">
          <TextArea :label="$t('your-message')" v-model="form.message" :content="form.message"
                    :error="fieldErrors.includes('message')"/>
      </div>
      <div class="mb-2 mt-4">
        <Button @click="sendMessage()" :theme="'solid-green'" :size="'medium'" :text="$t('send')"/>
      </div>
    </div>
  </Modal>

</template>