<template>
  <div class="">
  </div>
</template>

<script>
export default {
  name: 'UserMailConfirmation',
  async mounted() {
    let vm = this;
    const token = this.$route.query.token;
    if (token) {
      await this.$store.dispatch('utils/openNotificationModal', {message: "Моля изчакайте... ...", type: 'loading'});
      try {
        await this.$store.dispatch('user/confirmEmail', {
          token: token
        });
        await this.$store.dispatch('utils/openNotificationModal', {
          type: 'success',
          message: this.$t('email-confirmation-success')
        });
      } catch (e) {
        await this.$store.dispatch('utils/openNotificationModal', {
          type: 'error',
          message: this.$t('email-confirmation-error')
        });
      } finally {
        this.$router.push({name: 'Index'});
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";


</style>