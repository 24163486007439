import Button from "@/components/Common/Buttons/Button/Button";
import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import StepProgressBar from "@/components/Components/StepProgressBar/StepProgressBar";
import PremiumAdSelection from "@/components/Components/PremiumAdSelection/PremiumAdSelection";
import CreditWallet from "@/components/Components/CreditWallet/CreditWallet";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import FileInput from "@/components/Common/Inputs/FileInput/FileInput";
import FileService from '@/services/FileService';
import xIcon from "@/components/Common/Icons/xIcon/";

export default {
    name: 'AddRoommateOffer',
    components: {
        Button,
        Dropdown,
        InputField,
        StepProgressBar,
        TextArea,
        FileInput,
        CreditWallet,
        PremiumAdSelection,
        xIcon
    },
    data() {
        return {
            stage: 0,
            form: {
                title: null,
                name: null,
                phone: null,
                email: null,
                price: null,
                description: null,
                districtId: null,
                universityId: null,
                images: [],
            },
            formErrors: [],
            cities: null,
            //Holds the cities mapped as {name: id}
            citiesMapped: null,
            //Holds the selected city id, so it can be used to get the districts
            propertyLocationCity: null,
            //Holds the districts for the selected city
            propertyLocationDistricts: {},
            universities: null,
            isOfferEditing: false,
            selectedPlan: 'regular'
        };
    },
    watch: {
        propertyLocationCity: async function (cityId, oldVal) {
            if (cityId !== 1) {
                this.form.universityId = null;
            }
            this.propertyLocationDistricts = await this.$store.dispatch("location/getDistrictsMapped", cityId);
        }
    },
    computed: {
        user() {
            return this.$store.state.user.info;
        },
        isRegularPlanSelected() {
            return this.selectedPlan === 'regular';
        },
        isPremium7PlanSelected() {
            return this.selectedPlan === 'premium-7';
        },
        isPremium30PlanSelected() {
            return this.selectedPlan === 'premium-30';
        },
    },
    async mounted() {
        let vm = this;
        const id = this.$route.params.id;
        if (id) {
            this.form = await this.$store.dispatch("roommate/getSingleRoommatePost", {id: id});
            this.form.districtId = this.form.district.id;
            this.propertyLocationCity = this.form.city.id;
        }

        this.isOfferEditing = this.$route.name === 'EditRoommateOffer';
        this.cities = await this.$store.dispatch("location/getCities");
        this.citiesMapped = await this.$store.dispatch("location/getCitiesMapped");

        const universities = await this.$store.dispatch('roommate/getRoommateUniversities');
        this.universities = {};
        universities.forEach((item) => {
            vm.universities[this.$t(item.name)] = item.id;
        });
    },
    methods: {
        async uploadPhoto(event) {

            const files = event.target.files;
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                formData.append('file[]', file, file.name);
            }
            const images = await FileService.uploadFile(formData);
            this.form.images = this.form.images.concat(images);
        },
        goToStage(goToStage) {
            if (!this.validateFields(goToStage)) {
                return;
            }
            this.stage = goToStage;
        },
        validateFields(goToStage) {
            this.formErrors = [];

            if (goToStage === 1) {
                if (this.form.title === null || this.form.title === '') {
                    this.formErrors.push('title');
                }
                if (this.form.name === null || this.form.name === '') {
                    this.formErrors.push('name');
                }
                if (this.form.phone === null || this.form.phone === '') {
                    this.formErrors.push('phone');
                }
                const mailRegex = /\S+@\S+\.\S+/;
                if (this.form.email === null || this.form.email === '' || !mailRegex.test(this.form.email)) {
                    this.formErrors.push('email');
                }
                if (this.form.description === null || this.form.description === '') {
                    this.formErrors.push('description');
                }
                if (this.form.price === null || this.form.price === '') {
                    this.formErrors.push('price');
                }
            }

            if (goToStage === 2) {
                if (this.form.districtId === null || this.form.districtId === '') {
                    this.formErrors.push('districtId');
                }
                if (this.propertyLocationCity === null || this.propertyLocationCity === '') {
                    this.formErrors.push('propertyLocationCity');
                }
                if (this.form.images.length === 0) {
                    this.formErrors.push('images');
                }
            }

            return this.formErrors.length === 0;
        },
        removeImage(index) {
            this.form.images.splice(index, 1);
        },
        async publish() {
            try {
                let post = null;
                const payload = {plan: this.selectedPlan, ...this.form};
                if (this.isOfferEditing) {
                    this.form.id = this.$route.params.id;
                    post = await this.$store.dispatch('roommate/updateRoommatePost', payload);
                } else  {
                    post = await this.$store.dispatch('roommate/publishRoommatePost', payload);
                }
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'success',
                    message: this.$t('offer-submited-success')
                });
                this.form = {name: null, phone: null, email: null, price: null, description: null, districtId: null, images: [],};
                this.$router.push({name: 'SingleRoommateOffer', params: {id: post.id}});
            } catch (err) {
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'error', message: err.message
                });
            }
        },
        selectPlan(plan) {
            let hasEnoughCredits = false;
            const userCredits = this.$store.state.user.info.credits;
            switch (plan) {
                case 'regular':
                    hasEnoughCredits = userCredits >= 1;
                    break;
                case 'premium-7':
                    hasEnoughCredits = userCredits >= 10;
                    break;
                case 'premium-30':
                    hasEnoughCredits = userCredits >= 20;
                    break;
            }

            if (hasEnoughCredits) {
                this.selectedPlan = plan;
            } else {
                this.$store.dispatch('utils/openNotificationModal', {
                    message: this.$t('you-have-no-enough-credits-for-selected-plan'),
                    type: 'error'
                });
            }
        },
    },
};