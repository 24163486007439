import Axios from '@/utils/axios';
import axios from "axios";
export default {
  namespaced: true,
  state: {
    categories: [],
    list: {},
    similar: {},
    listByVendor: [],
  },
  mutations: {
    SET_PRODUCT(state, payload) {
      state.list[payload.id] = payload;
    },
    SET_PRODUCTS(state, payload) {
      state.list = payload;
    },
    SET_PRODUCT_CATEGORIES(state, payload) {
      state.categories = payload;
    },
    SET_PRODUCTS_BY_CATEGORY(state, payload) {
		state.similar = payload;
    },
    SET_PRODUCTS_BY_VENDOR(state, payload) {
      state.listByVendor = payload
    }
  },
  actions: {
    async createProduct({ commit }, data) {
      const response = await Axios.post('/api/product', data);
      return response.data;
    },
    async updateProduct({ commit }, product) {
      const response = await Axios.post('/api/product/' + product.id, product);
      return response.data;
    },
    async getProduct({ commit }, id) {
      const response = await Axios.get('/api/product/' + id);
      commit('SET_PRODUCT', response.data);
      return response.data;
    },
    async getVendorProducts({ commit }, pagination) {
      const response = await Axios.get('/api/vendor/product?offset=' + pagination.offset + '&limit=' + pagination.limit);
      // commit('SET_PRODUCT', response.data);
      return response.data;
    },
    async getProductCategories({ commit }, payload = null) {
      let params = '?n=0';
      if (payload) {
        if (payload.hasOwnProperty('room') && payload.room) {
          params += '&room=' + payload.room;
        }
        if (payload.hasOwnProperty('promo') && payload.promo) {
          params += '&promo=' + payload.promo;
        }
      }
      const response = await Axios.get('/api/product/category' + params);
      commit('SET_PRODUCT_CATEGORIES', response.data);
      return response.data;
    },
    async getProductsFromCategory({ commit }, category) {
      const response = await Axios.post(`/api/product/search`, {categoryId: category});
      commit('SET_PRODUCTS_BY_CATEGORY', response.data);
    },
    async getStaticPromoProductCategories() {
      const response = await Axios.get(`/static/selected-promo-categories.json`);
      return response.data
    },
    async getProductsByVendor({ commit }, vendorId) {
      const response = await Axios.post(`/api/product/search`, {vendorId: vendorId});
      commit('SET_PRODUCTS_BY_VENDOR', response.data);
    },
    async getImportProcess({ commit }) {
      const response = await Axios.get(`/api/product/import`);
      return response.data;
    },
    async changeImportProcessStatus({ commit }, payload) {
      const response = await Axios.post(`/api/product/import/` + payload.id, {status: payload.status});
      return response.data;
    },
    async searchProduct({ commit }, params = {}) {
      const orderBy =  params.hasOwnProperty('orderBy') ? params.orderBy : 'price.DESC';
      const offset =  params.hasOwnProperty('offset') ? params.offset : 0;
      const limit =  params.hasOwnProperty('limit') ? params.limit : 10;
      const request =  params.hasOwnProperty('request') ? params.request : {};
      const response = await Axios.post('/api/product/search?offset=' + offset + ' &limit=' + limit + '&orderBy=' + orderBy, request);
      return response.data;
    },
    async getProductCategoryFilters({ commit }, categoryId) {
      const response = await Axios.get('/api/product/category/filters/' + categoryId);
      return response.data;
    },
    async deleteProduct({ commit }, id) {
      await Axios.delete('/api/product/' + id);
    },
    async importProducts({ commit }, payload) {
      const response = await axios.post('/api/product/import', payload.file, {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      });
      return response.data;
    },
  }
};