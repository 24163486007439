<template>
  <svg class="icon-close-svg" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame 658">
      <path id="Vector 4" d="M0.857422 0.856689L19.9998 19.9991" stroke-width="1.00879" stroke-linecap="round" />
      <path id="Vector 5" d="M20 0.856689L0.857622 19.9991" stroke-width="1.00879" stroke-linecap="round" />
    </g>
  </svg>
</template>



<style>
.icon-close-svg {
  stroke: currentColor;
  color: #000000; /* Default stroke color */
}
</style>