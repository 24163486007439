<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div id="marketplace-category-container" class="container py-4">
    <div class="row">
      <div class="col-md-2 filter-container pt-3 d-none d-md-block">
        <div class="filter-title">{{ $t('filtering') }}</div>
        <div>
          <div class="filter-title">{{ $t('price') }}</div>
          <div class="row mb-3">
            <div class="col-md-12 mt-3">
              <InputField @input="updateFilters"
                          v-model="priceFrom"
                          :content="priceFrom"
                          :inputtype="'text'"
                          :label="$t('price-from')"
                          :size="'small'"/>
            </div>
            <div class="col-md-12 mt-2">
              <InputField @input="updateFilters"
                          v-model="priceTo"
                          :content="priceTo"
                          :inputtype="'text'"
                          :label="$t('price-to')"
                          :size="'small'"/>
            </div>
          </div>
        </div>
        <div>
          <div class="d-flex align-items-center mt-4 mb-3">
            <div class="d-flex mt-2">
              <CheckBox v-model="promo" :checked="promo"/>
            </div>
            <div class="promo-filter-label ms-2">{{ $t('promo-filter-label') }}</div>
          </div>
        </div>
        <div v-for="filter in filters" v-if="!isNewProductsPage && !isPromoProductsPage">
          <MarketplaceFilter
              v-model="appliedFilters[filter.title]"
              :title="filter.title"
              :filtertype="'checkbox'"
              :options="filter.options"
          />
        </div>
      </div>
      <div class="col-md-10 products-container pt-4"
           v-bind:class="{ 'open-filter-container-mobile': filterContainerActive }">
        <div class="d-flex">
          <div class="me-auto">
            <MarketplaceGridTitle :title="$t(category.title)" v-if="category"/>
          </div>
          <div class="d-none d-md-block">
            <MarketplaceSortFilter @sort="sortResults"/>
          </div>
        </div>


        <div class="row d-md-none">
          <div class="col-6" v-on:click="toggleFilterContainer">
            <AboutButton :text="$t('filtering')"/>
          </div>
          <div class="col-6 mb-3">
            <MarketplaceSortFilter @sort="sortResults"/>
          </div>
          <div class=" filter-container-mobile d-md-none">
            <div class="mt-4 filter-title align-items-center" v-on:click="toggleFilterContainer">
              <div class="mb-3">
                <MarketplaceGridTitle :title="$t(category.title)" v-if="category"/>
              </div>
              <div class="d-flex">
                <div class=" me-auto">{{ $t('filtering') }}</div>
                <ModalCloseBtn/>
              </div>
            </div>

            <div>
              <div class="filter-title">{{ $t('price') }}</div>
              <div class="row mb-3">
                <div class="col-md-12 mt-3">
                  <InputField @input="updateFilters"
                              v-model="priceFrom"
                              :content="priceFrom"
                              :inputtype="'text'"
                              :label="$t('price-from')"
                              :size="'small'"/>
                </div>
                <div class="col-md-12 mt-2">
                  <InputField @input="updateFilters"
                              v-model="priceTo"
                              :content="priceTo"
                              :inputtype="'text'"
                              :label="$t('price-to')"
                              :size="'small'"/>
                </div>
              </div>
            </div>

            <div class="marketplace-filter" v-for="filter in filters">
              <MarketplaceFilter
                  v-model="appliedFilters[filter.title]"
                  :title="filter.title"
                  :filtertype="'checkbox'"
                  :options="filter.options"
              />
            </div>

            <div class="row">
              <div class=" mt-4" v-on:click="toggleFilterContainer">
                <Button :text="$t('apply-filters')" :size="'medium'" :theme="'solid-orange'"/>
              </div>
              <div class="col-md-6 mb-5 mt-3" @click="clearFilters()" v-if="false">
                <Button :text="$t('clear-filters')" :size="'medium'" :theme="'solid-grey'"/>
              </div>
            </div>
          </div>
        </div>

        <div v-if="products">
          <MarketplaceGrid :products="products"/>
        </div>

        <div class="pagination mt-5 mb-5" v-if="products && products.length > 0">
          <Pagination
              :size="'medium'"
              :pages="Math.ceil(total/limit)"
              :route="'MarketplaceCategoryPage'"
              :current="currentPage"
              @pageChanged="handlePageChange"
          />
        </div>
        <div class="alert alert-danger" role="alert" v-else-if="products !== null">
          {{ $t('alert-no-products-found-in-category') }}
        </div>
        <PreLoader v-if="products === null"/>

      </div>
    </div>
  </div>
</template>
