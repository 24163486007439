<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<style>
.index-marketplace-carousel-wrapper {
  .carousel__next, .carousel__prev {
    top: 150px;
  }
}
</style>

<template>
    <div v-bind:class="{
    'single-property-marketplace-carousel index-marketplace-carousel-wrapper': true,
  }">
        <div class="products-top-nav">
            <div class="d-flex" v-if="carouseltype === 'promo-products'">
                <div class="products-title d-flex align-items-center me-auto">
                    <LabelPromo :language="'bulgarian'"/>
                    <router-link :to="{name: 'MarketplaceCategoryPage', params: {id: 'promo'}}">
                        <span class="ms-2">{{ $t('recommended-promo-products') }}</span>
                    </router-link>
                </div>
                <div class="d-none d-md-block">
                    <router-link :to="{name: 'MarketplaceCategoryPage', params: {id: 'promo'}}">
                        {{ $t('all') }} <span>  {{ $t('promo') }} </span> {{ $t('products') }}
                    </router-link>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_2059_9538)">
                            <path d="M1.20005 6L10.8 6" stroke="#434D56" stroke-width="0.9" stroke-linecap="round"/>
                            <path d="M7.20005 9.59998L10.8 5.99998L7.20005 2.39998" stroke="#434D56" stroke-width="0.9"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_2059_9538">
                                <rect width="12" height="12" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
            <div class="d-flex" v-if="carouseltype === 'new-products'">
                <div class="products-title d-flex align-items-center me-auto">
                    <LabelNew :theme="'white-text'"/>
                    <router-link :to="{name: 'MarketplaceCategoryPage', params: {id: 'new'}}">
                        <span class="ms-2">{{ $t('all-new-products') }}</span>
                    </router-link>
                </div>
                <div class="d-none d-md-block">
                    <router-link :to="{name: 'MarketplaceCategoryPage', params: {id: 'new'}}">
                        {{ $t('all') }} <span>  {{ $t('new') }} </span> {{ $t('products') }}
                    </router-link>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_2059_9538)">
                            <path d="M1.20005 6L10.8 6" stroke="#434D56" stroke-width="0.9" stroke-linecap="round"/>
                            <path d="M7.20005 9.59998L10.8 5.99998L7.20005 2.39998" stroke="#434D56" stroke-width="0.9"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_2059_9538">
                                <rect width="12" height="12" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>

        <div class="marketplace-carousel " v-if="products">
            <Carousel v-bind="settings" :breakpoints="breakpoints">
                <Slide v-for="(product, product_index) in products" :key="product_index">
                    <div class="slider_item">
                        <MarketplaceAd
                            :id="product.id"
                            :labelnew="true /* todo:! */"
                            :labeldiscount="true"
                            :title="product.title"
                            :category="product.categories.length > 0 ? $t(product.categories[0].title) : null"
                            :regularamount="product.price"
                            :discountedamount="product.promoPrice"
                            :image="product.mainImage !== null ? product.mainImage.src : ''"
                        />
                    </div>
                </Slide>
                <template #addons>
                    <Navigation>
                        <template #next>
                            <div class="buttons-left-right button-right">
                                <svg width="18" height="14" viewBox="0 0 18 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_1747_9026)">
                                        <path d="M1 7L17 7" stroke="#434D56" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M11 13L17 7L11 0.999999" stroke="#434D56" stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1747_9026 ">
                                            <rect width="18" height="14" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </template>
                        <template #prev>
                            <div class=" buttons-left-right button-left">
                                <svg width="18" height="14" viewBox="0 0 18 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_1747_16541)">
                                        <path d="M17 7L1 7" stroke="#434D56" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 1L1 7L7 13" stroke="#434D56" stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1747_16541">
                                            <rect width="18" height="14" fill="white"
                                                  transform="translate(18 14) rotate(180)"/>
                                        </clipPath>
                                    </defs>
                                </svg>

                            </div>
                        </template>
                    </Navigation>
                </template>
            </Carousel>

        </div>

    </div>

</template>

