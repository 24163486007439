import Button from "@/components/Common/Buttons/Button/Button";
import AboutButton from "@/components/Common/Buttons/AboutButton/AboutButton";
import TrustedAgenciesIcon from "@/components/Common/Icons/TrustedAgenciesIcon.vue";
import PhoneCallButton from "@/components/Components/PhoneCallButton/PhoneCallButton.vue";

export default {
    name: 'AgencyInfoBox',
    props: {
        date: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        description1: {
            type: String,
            default: null
        },
        description2: {
            type: String,
            default: ''
        },
        description3: {
            type: String,
            default: ''
        },
        description4: {
            type: String,
            default: ''
        },
        phones: {
            type: Array,
            default: []
        },
        emails: {
            type: Array,
            default: null
        },
        address: {
            type: String,
            default: null
        },
        profilePicture: {
            type: String,
            default: null
        },
    },
    components: {
        PhoneCallButton,
        TrustedAgenciesIcon,
        Button,
        AboutButton,
    },
    mounted() {
    }
}