<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'input-field': true,
    'progress-slider-grey': false
  }">
    <div class="filter-wrapper" v-bind:class="{ 'open-filter-box': filterBoxActive }">
      <div class="d-flex title-container"  v-on:click="toggleFilterBox">
        <div class="filter-title me-auto">{{ title }}</div>
        <FilterArrowDownIcon/>
      </div>

      <div class="filter-slider" v-if="filtertype === 'slider'">
        <div class="row">
          <div class="col-md-12 mt-3">
            <InputField v-model="priceFrom" :content="priceFrom" :inputtype="'text'" :size="'small'"/>
          </div>
          <div class="col-md-12 mt-2">
            <InputField v-model="priceTo" :content="priceTo" :inputtype="'text'" :size="'small'"/>
          </div>
        </div>
      </div>

      <div class="filter-checkbox" v-if="filtertype === 'checkbox' || filtertype === 'radio'">
        <div class="d-flex filter-option" v-for="option in options">
          <div v-if="filtertype === 'checkbox'">
            <CheckBox v-model="value[option]" :value="option"/>
          </div>
          <div v-if="filtertype === 'radio'">
            <RadioButton/>
          </div>
          <div class="filter-option-text" >{{ option }}</div>
        </div>

      </div>
    </div>
  </div>
</template>

