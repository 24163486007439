<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'dropdown': true,
    'right-inner-addon': true,
    'dropdown-size-large': size === 'large',
    'dropdown-size-small': size === 'small',
    'error': error,
  }">
    <select id="default" class="dropdown dropdown-toggle" @change="handleInput">
      <option :value="null" :selected="selectedProp ===  null" :disabled="!nullable">{{ label }}</option>
      <option v-for="(key, value) in options" :value="key" :selected="value == selectedProp || key == selectedProp">
        {{ value.length > 0 ? $t(value) : value }}
      </option>
    </select>
    <svg v-if="size !=='small'" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_242_29863)">
        <path
          d="M7.75 1C7.75 0.585787 7.41421 0.25 7 0.25C6.58579 0.25 6.25 0.585787 6.25 1L7.75 1ZM6.25 13C6.25 13.4142 6.58579 13.75 7 13.75C7.41421 13.75 7.75 13.4142 7.75 13L6.25 13ZM6.25 1L6.25 13L7.75 13L7.75 1L6.25 1Z"
          fill="#A9B2B9" />
        <path
          d="M1.53033 6.46979C1.23744 6.1769 0.762563 6.1769 0.46967 6.46979C0.176776 6.76269 0.176776 7.23756 0.46967 7.53045L1.53033 6.46979ZM7 13.0001L6.46967 13.5305C6.76256 13.8233 7.23744 13.8233 7.53033 13.5305L7 13.0001ZM13.5303 7.53045C13.8232 7.23756 13.8232 6.76268 13.5303 6.46979C13.2374 6.1769 12.7626 6.1769 12.4697 6.46979L13.5303 7.53045ZM0.46967 7.53045L6.46967 13.5305L7.53033 12.4698L1.53033 6.46979L0.46967 7.53045ZM7.53033 13.5305L13.5303 7.53045L12.4697 6.46979L6.46967 12.4698L7.53033 13.5305Z"
          fill="#A9B2B9" />
      </g>
      <defs>
        <clipPath id="clip0_242_29863">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>

  </div>
</template>

