<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
    <div id="main-container" class="" v-if="serviceName">
        <div class="moving-cleaning-title container">
            {{ translate('moving-heading-title') }}
        </div>
        <div class="moving-cleaning-heading-banner"
             :style="{ 'background-image': 'url(/static/' + serviceName + '-header-image.png' }">
            <div class="container">
                <div class="  d-flex justify-content-center">
                    <div class="heading-content-container">
                        <img :src="'/static/' + serviceName +  '-header-logo.png'" alt="">
                        <div class="moving-cleaning-subtitle"> {{ translate('subtitle') }}</div>
                        <div class="moving-cleaning-description ">{{ translate('description') }}</div>
                        <div class=" d-flex justify-content-center">
                            <div @click="scrollToServices">
                                <Button :theme="'solid-orange'" :size="'medium'" :text="translate('request-service')"/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="container our-services-wrapper" ref="servicesList" id="services">
            <div class="our-services-title"> {{ translate('our-services-title') }}</div>
            <div class="moving-cleaning-description"> {{ translate('our-services-description') }}</div>
            <div>
                <CleaningLocationHoursInfo :service="serviceName"/>
            </div>
            <div class="row">
                <div class="col-md-4 d-flex align-items-stretch" v-for="service in services[serviceName]">
                    <div class="service-wrapper text-center">
                        <div class="d-flex justify-content-center align-items-center icons">
                            <SingleCleaningIcon v-if="service.name === 'one-time'"/>
                            <DeepCleaningIcon v-if="service.name === 'deep-cleaning'"/>
                            <SubscriptionCleaningIcon v-if="service.name === 'subscription-cleaning'"/>

                            <MovingIcon v-if="service.name === 'moving-moving-title'"/>
                            <MovingTransportationIcon v-if="service.name === 'moving-transport-title'"/>
                            <MovingHelperIcon v-if="service.name === 'moving-helper-title'"/>
                        </div>
                        <div class="service-title"> {{ $t(service.name) }}</div>
                        <div class="service-description"> {{ translate(service.description) }}</div>
                        <div class=" d-flex justify-content-center">
                            <router-link :to="service.link">
                                <Button :theme="'solid-orange'" :size="'medium'" :text="translate('request-service')"/>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="process-wrapper">
            <div class="container">
                <div class="process-title"> {{ translate('whats-the-process-title') }}</div>
                <div class="row">
                    <div class="col-md-6 process-description" v-html="translate('process-description-left')"></div>
                    <div class="col-md-6 process-description" v-html="translate('process-description-right')"></div>
                </div>
            </div>
        </div>
    </div>
</template>
