import Button from "@/components/Common/Buttons/Button/Button";
import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import CartItem from "@/components/Components/CartItem/CartItem";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import RadioButton from "@/components/Common/Inputs/RadioButton/RadioButton";
import TruckIcon from "@/components/Common/Icons/TruckIcon";
import InfoIcon from "@/components/Common/Icons/InfoIcon";

export default {
    name: 'CART',
    components: {
        Button,
        Dropdown,
        InputField,
        CartItem,
        TextArea,
        CheckBox,
        RadioButton,
        TruckIcon,
        InfoIcon
    },
    data() {
        return {
            order: {},
            orderValidated: null,
            formErrors: [],
            invoiceType: null,
            citiesOptions: [],
            wasFormSent: false,
            cancellationAgreed: false, //cancellation policy checkbox
            termsAgreed: false,        //t&c checkbox
        }
    },
    computed: {
        products() {
            return this.$store.state.cart.products;
        }
    },
    watch: {
        cancellationAgreed: {
            handler(newVal, oldVal) {
                this.orderValidated = this.validateOrder(this.wasFormSent);
            },
            deep: true,
            immediate: true
        },
        termsAgreed: {
            handler(newVal, oldVal) {
                this.orderValidated = this.validateOrder(this.wasFormSent);
            },
            deep: true,
            immediate: true
        },
        order: {
            handler(newVal, oldVal) {
                this.orderValidated = this.validateOrder(this.wasFormSent);
            },
            deep: true,
            immediate: true
        }
    },
    async mounted() {
        this.order = this.initOrder();
        await this.$store.dispatch("location/getCities");
        this.cities = this.$store.state.location.cities ?? null;
        this.citiesOptions = this.$store.state.location.citiesOptions ?? null;
    },
    methods: {
        initOrder() {
            return {
                name: "",
                phone: "",
                email: "",
                deliveryType: 1,
                deliveryCity: "",
                deliveryAddress: "",
                invoiceDetails: {
                    copmanyName: "",
                    companyAddress: "",
                    eik: "",
                    accountablePerson: "",
                    companyId: "",
                },
                paymentType: null,
                additionalInfo: "",
                products: [],
                productQuantities: {},

                //local only
                isInvoiceRequired: null,

                hasAdditionalInfo: 0,
            };
        },
        async sendOrder() {
            this.wasFormSent = true;
            if (!this.validateOrder(true)) {
                return;
            }

            let vm = this;
            const products = this.$store.state.cart.products;

            if (products === null) {
                this.$store.dispatch("utils/openNotificationModal", {
                    message: this.$t('error-order-failed'),
                    type: 'error'
                });
                return;
            }

            Object.keys(products).forEach((vendorId) => {
                Object.keys(products[vendorId]['products']).forEach((key, index) => {
                    const product = products[vendorId]['products'][key];
                    vm.order.products.push(product.id);
                    vm.order.productQuantities[product.id] = product.quantity;
                });
            });

            this.order.products = [...new Set(this.order.products)];

            try {
                const response = await this.$store.dispatch('cart/checkout', {order: this.order});

                this.order = this.initOrder();
                this.formErrors = [];

                const paymentUrl = response.paymentUrl;
                window.location.replace(paymentUrl);
            } catch (error) {

                if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                    this.$store.dispatch('utils/openModal', 'login');
                    return;
                }

                this.$store.dispatch("utils/openNotificationModal", {
                    message: this.$t('error-order-failed') + ': ' + error.message,
                    type: 'error'
                });
            }
        },
        validateOrder(flagFieldsAsInvalid = true) {
            let isValid = true;
            let vm = this;

            this.formErrors = [];
            const formErrors = []

            Object.keys(this.order).forEach((key) => {
                if (['isInvoiceRequired', 'paymentType', 'hasAdditionalInfo'].includes(key)) {
                    const validation = vm.order[key] === null;
                    if (validation !== false) {
                        formErrors.push(key);
                        isValid = false;
                    }
                }
                const notEmpty = ['name', 'phone', 'deliveryType', 'email', 'deliveryAddress'];
                if (notEmpty.includes(key)) {
                    const validation = vm.order[key] === null || vm.order[key].length < 2 || vm.order[key] === "";
                    if (validation !== false) {
                        formErrors.push(key);
                        isValid = false;
                    }
                }
            });

            if (vm.cancellationAgreed !== true) {
                formErrors.push('cancellationAgreed');
                isValid = false;
            }

            if (vm.termsAgreed !== true) {
                formErrors.push('termsAgreed');
                isValid = false;
            }

            if (flagFieldsAsInvalid) {
                vm.formErrors = formErrors;
            }

            return isValid;
        }
    },
}