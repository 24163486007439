
export default {
    name: 'LabelNew',
    props: {
        theme: {
            type: String,
            default: 'medium'
        },
        english: {
            type: String,
            default: ''
        },
        theme: {
            type: String,
            default: ''
        }
    },
    components: {

    },
    mounted() {
    }
}