<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'label-new': theme === 'dark-text',
    'label-new-white-text': theme === 'white-text',

  }" class="d-flex justify-content-center align-items-center">
  <div class=" label-new-english " v-if="$root.$i18n.locale === 'en'">New</div>
  <div class=" label-new-bulgarian " v-if="$root.$i18n.locale === 'bg'">ново</div>

  </div>
</template>

