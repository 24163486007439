import Button from "@/components/Common/Buttons/Button/Button";
import ModalCloseBtn from "../ModalCloseBtn";
import InputField from '@/components/Common/Inputs/Input/Input';
import CheckBox from '@/components/Common/Inputs/CheckBox/CheckBox';
import Modal from './../Modal';

export default {
    name: 'SignUpModal',
    components: {
        Button,
        ModalCloseBtn,
        InputField,
        Modal,
        CheckBox
    },
    data() {
        return {
            form: {
                name: null,
                phone: null,
                email: null,
                password: null,
                optMarketingFlag: false,
            },
            confirmPassword: null,
            termsFlag: false,
            fieldErrors: [],

            //refs
            termsFlagCheckbox: null,
            optMarketingFlagCheckbox: null
        }
    },
    mounted() {
        this.termsFlagCheckbox = this.$refs.termsFlagCheckbox;
        this.optMarketingFlagCheckbox = this.$refs.optMarketingFlagCheckbox;
    },
    methods: {
        async registerUser() {
            if (this.validateInputs() === false) {
                return;
            }

            if (this.termsFlag !== true) {
                this.$store.dispatch("utils/openNotificationModal", {
                    type: 'error',
                    message: this.$t('sign-up-modal-error-accept-terms')
                });
                return
            }

            try {
                await this.$store.dispatch('utils/openNotificationModal', {type: 'loading'});
                await this.$store.dispatch("user/registerUser", {
                    name: this.form.name,
                    phone: this.form.phone,
                    email: this.form.email,
                    password: this.form.password
                });
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'success',
                    message: this.$t('successful-registration-message')
                });
                this.form = {
                    name: null,
                    phone: null,
                    email: null,
                    password: null,
                    optMarketingFlag: false,
                };
                this.termsFlag = false;
                this.termsFlagCheckbox.setChecked(this.termsFlag);
                this.optMarketingFlagCheckbox.setChecked(this.termsFlag);
                this.confirmPassword = null;
            } catch (err) {
                if ('response' in err) {
                    switch (err.response.data.message) {
                        case 'validation_failed':
                            this.setFieldsErrors(err)
                            await this.$store.dispatch('utils/openNotificationModal', {
                                message: this.$t('error-please-fulfill-all-fields-with-valid-data'),
                                type: 'error'
                            });
                            break;
                        default:
                            await this.$store.dispatch('utils/openNotificationModal', {
                                message: this.$t(err.response.data.message),
                                type: 'error'
                            });
                            break;
                    }
                } else {
                    await this.$store.dispatch('utils/openNotificationModal', {
                        type: 'error',
                        message: this.$t('error-please-try-again-later')
                    });
                }
            } finally {
                await this.$store.dispatch('utils/closeModal', 'loading');
            }
        },
        setFieldsErrors(err) {
            let vm = this;
            vm.fieldErrors = [];
            err.response.data.errors.forEach((item) => {
                vm.fieldErrors.push(item.property);
                if (item.property === 'confirmPassword') {
                    vm.fieldErrors.push('password');
                }
            });
        },
        validateInputs() {
            this.fieldErrors = [];
            if (this.form.name === null || this.form.name === '') {
                this.fieldErrors.push('name');
            }
            if (this.form.phone === null || this.form.phone === '') {
                this.fieldErrors.push('phone');
            }
            if (this.form.email === null || this.form.email === '') {
                this.fieldErrors.push('email');
            }
            if (this.form.password === null || this.form.password === '') {
                this.fieldErrors.push('password');
            }
            if (this.confirmPassword === null || this.confirmPassword === '') {
                this.fieldErrors.push('confirmPassword');
            }
            if (this.form.password !== this.confirmPassword) {
                this.fieldErrors.push('confirmPassword');
            }

            return this.fieldErrors.length === 0;
        }
    }
}