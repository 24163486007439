import Button from "@/components/Common/Buttons/Button/Button";
import ModalCloseBtn from "../ModalCloseBtn";
import InputField from '@/components/Common/Inputs/Input/Input';
import CheckBox from '@/components/Common/Inputs/CheckBox/CheckBox';
import Modal from './../Modal';

export default {
    name: 'PassChangeModal',
    components: {
        Button,
        ModalCloseBtn,
        InputField,
        Modal,
        CheckBox
    },
    data() {
        return {
            form: {
                password: null,
                password2: null,
            },
            fieldErrors: []
        }
    },
    methods: {
        async changePass() {
            if (this.validateInputs() === false) {
                return;
            }

            const token = this.$route.query.token;
            if (!token) {
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'error',
                    message: this.$t('error-try-again')
                });
                return;
            }

            try {
                await this.$store.dispatch('utils/openNotificationModal', {type: 'loading'});
                await this.$store.dispatch("user/changePassword", {
                    token: token,
                    password: this.form.password,
                });
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'success',
                    message: this.$t('password-updated-successfully')
                });
            } catch (e) {
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'error',
                    message: this.$t('error-try-again')
                });
            } finally {
                await this.$store.dispatch('utils/closeModal', 'loading');
                await this.$store.dispatch('utils/closeModal', 'passChange');
            }
        },
        validateInputs() {
            this.fieldErrors = [];
            if (this.form.password === null || this.form.password === '' || this.form.password.length < 6) {
                this.fieldErrors.push('password');
            }
            if (this.form.password2 === null || this.form.password2 === '') {
                this.fieldErrors.push('password2');
            }
            if (this.form.password !== this.form.password2) {
                this.fieldErrors.push('password2');
            }

            return this.fieldErrors.length === 0;
        }
    }
}