import Button from "@/components/Common/Buttons/Button/Button";
import ModalCloseBtn from "../ModalCloseBtn";
import InputField from '@/components/Common/Inputs/Input/Input';
import CheckBox from '@/components/Common/Inputs/CheckBox/CheckBox';
import Modal from './../Modal';

export default {
    name: 'ForgottenPassModal',
    components: {
        Button,
        ModalCloseBtn,
        InputField,
        Modal,
        CheckBox
    },
    data() {
        return {
            form: {
                password: null,
                password2: null,
            },
            fieldErrors: []
        }
    },
    methods: {
        async requestChangePassEmail() {
            if (this.validateInputs() === false) {
                return;
            }

            try {
                await this.$store.dispatch('utils/openNotificationModal', {type: 'loading'});
                await this.$store.dispatch("user/requestForgottenPasswordMail", {
                    email: this.form.email,
                });
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'success',
                    message: this.$t('password-change-success-message')
                });
            } catch (e) {
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'error',
                    message: this.$t('error-try-again')
                });
            } finally {
                await this.$store.dispatch('utils/closeModal', 'loading');
                await this.$store.dispatch('utils/closeModal', 'forgottenPass');
            }
        },
        validateInputs() {
            this.fieldErrors = [];

            // Check if email is empty or less than 6 characters
            if (this.form.email === null || this.form.email === '' || this.form.email.length < 6) {
                this.fieldErrors.push('email');
            } else {
                // Email format validation
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailPattern.test(this.form.email)) {
                    this.fieldErrors.push('email format');
                }
            }

            // Return true if there are no errors
            return this.fieldErrors.length === 0;
        }
    }
}