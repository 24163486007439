<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="banner-container ">
      <div class="image-banner"></div>
      <div class="subtitle-regular">{{ $t('index-banner-top-subtitle') }}</div>
      <div class="subtitle-large d-flex align-items-center">
        <div class="me-auto">
          <div>{{ $t('index-banner-title-line-1') }}</div>
          <div>{{ $t('index-banner-title-line-2') }}</div>
        </div>


      </div>
      <div class="subtitle-regular">
        <div>{{ $t('index-banner-bottom-subtitle') }}</div>
      </div>
      <div class="button-products">
        <router-link :to="{name: 'MarketplaceProductPromoCategories'}">
          <Button :text="$t('index-banner-button')" :theme="'solid-white'" :size="'medium'" :svg="'orange-arrow-right'"/>
        </router-link>
      </div>
    </div>

  </div>

</template>

