<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div id="main-container" class="container">

    <div v-if="isLoading" class="mt-4">
      <PagePreloader layout="blog"/>
    </div>
    <div class="d-md-flex" v-else>
      <div class="mb-4">
        <div class="categories-menu-wrapper" v-bind:class="{ 'open-categories-menu-mobile': categoriesMenuActive }">
          <div class="blog-categories-menu-title d-flex " @click="toggleCategoriesMenu">
            <div class="me-auto d-none d-md-block"> {{ $t('categories') }}</div>
            <div class="me-auto d-md-none"> {{ $t(selectedCategory.title) }}</div>
            <div class="d-md-none">
              <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="11.5" y1="0.5" x2="0.5" y2="0.499999" stroke="#1A2128" stroke-linecap="round"/>
                <line x1="11.5" y1="3.5" x2="0.5" y2="3.5" stroke="#1A2128" stroke-linecap="round"/>
                <line x1="11.5" y1="6.5" x2="0.5" y2="6.5" stroke="#1A2128" stroke-linecap="round"/>
              </svg>
            </div>
          </div>
          <div class="categories-menu d-md-none">
            <div class="categories-menu-item"
                 v-bind:class="{'categories-menu-item-selected': parseInt(category.id) === parseInt($route.params.id) }"
                 v-for="(category, category_index) in sortingCategories"
                 :key="category_index"
                 @click="selectCategory(category)">
              <router-link :to="{ name: 'BlogCategory', params: { id: category.id } }">
                {{ $t(category.title) }}
              </router-link>
            </div>
          </div>
          <div class="desktop-categories-menu d-none d-md-block">
            <div class="categories-menu-item"
                 v-bind:class="{'categories-menu-item-selected': parseInt(category.id) === parseInt($route.params.id) }"
                 v-for="(category, category_index) in sortingCategories" :key="category_index"
                 @click="selectCategory(category)">
              <router-link :to="{ name: 'BlogCategory', params: { id: category.id } }">
                {{ $t(category.title)  }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="top-post-container">
        <div class="blog-categories-title "> {{ $t('latest-posts') }}</div>
        <div class="top-posts-wrapper" v-for="(item, blogPostIndex) in blog_new_posts" :key="blogPostIndex">
          <div class="my-2 mb-4">
            <BlogPostItem :id="item.id" :size="'large'" :title="item.title"
                          :category="$t(item.category)" :date="item.date" :image="item.picture"
                          :description="item.short_description"/>
          </div>
        </div>
      </div>
      <div class="latest-posts-container">
        <div class="blog-categories-title"> {{ $t('top-posts')}}</div>
        <div class="latest-posts-wrapper" v-for="(item, blogPostIndex) in blog_top_posts" :key="blogPostIndex">
          <div class="blog-post">
            <BlogPostItemSmall :id="item.id" :size="'regular'" :title="item.title"
                               :category="$t(item.category)" :date="item.date" :image="item.picture"
                               :description="item.short_description"/>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

