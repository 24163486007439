<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div id="main-container" class=" " v-if="product">
    <div class="product-top-container">
      <div class="container">
        <div class="row mb-4">
          <div class="col-md-5">
            <div class="d-flex d-md-none mb-3">
              <div class="arrow-left me-auto d-flex align-items-center">
                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_439_34103)">
                    <path d="M15.7613 6.33301L0.52356 6.33301" stroke="#1A2128" stroke-width="1.42854"
                          stroke-linecap="round"/>
                    <path d="M6.23771 0.619142L0.523559 6.33329L6.2377 12.0474" stroke="#1A2128" stroke-width="1.42854"
                          stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_439_34103">
                      <rect width="17.1424" height="13.333" fill="white"
                            transform="translate(17.1425 13.333) rotate(-180)"/>
                    </clipPath>
                  </defs>
                </svg>
                <div class="ms-2">{{ $t('back') }}</div>
              </div>
              <div>
                <AddToFavourites ref="favorites" size="small" class="pe-2" :product="{
                      id: product.id,
                      image: product.mainImage.src,
                      labeldiscount: promoPrice !== null,
                      title: product.title,
                      category: product.categories[0].title,
                      labelnew: false,
                      regularamount: product.price,
                      discountedamount: product.promoPrice,
                  }"/>
              </div>
            </div>
            <GalleryProduct v-if="product.images.length > 0" :images="product.images"/>
          </div>
          <div class="col-md-7">
            <div class="product-title-id">
              <SingleProductTitleID :product="product"/>
            </div>
            <div id="product-description">
              <ProductShortDescription :product="product"/>
            </div>
            <div class="product-price">
              <ProductPrice @addToCart="addToCart" :product="product"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="product-middle-container container d-flex">
      <div class="col-md-8 col-12">
        <div class="description-wrapper" id="fulldescription">
          <ProductFullDescription :product="product"/>
        </div>
        <div class="description-wrapper">
          <ProductCharacteristics :product="product"/>
        </div>
      </div>
      <div class="col-md-4 d-none d-md-block">
        <div class="vendor-profile-component">
          <VendorProfile
              :vendorId="product.vendor.id"
              :name="product.vendor.name"
              :date="product.vendor.createdAt"
              :description="product.vendor.description"
              :image="product.vendor.profilePicture"
          />
        </div>
        <div class="buy-now-component">
          <BuyNow @addToCart="addToCart(1)" :product="product"/>
        </div>
      </div>
    </div>
    <div class="product-bottom-container">
      <div class="carousel-wrapper grey-wrapper" v-if="product.categories.length > 0 && similarProducts">
        <div class="container">
          <div> {{ $t('more-products-from-category') }} {{ product.categories[0].title }}</div>
          <IndexMarketplaceCarousel :products="similarProducts"/>
        </div>
      </div>
      <div class="carousel-wrapper" v-if="listByVendor">
        <div class="container">
          <div> {{ $t('more-products-from-vendor') }} {{ product.vendor.title }}</div>
          <IndexMarketplaceCarousel :products="listByVendor"/>
        </div>
      </div>
    </div>
  </div>

</template>
