import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import LabelDiscount from "@/components/Common/Labels/LabelDiscount/LabelDiscount";
import AddToFavourites from "@/components/Components/AddToFavourites/AddToFavourites";


export default {
    name: 'MarketplaceAd',
    props: {
        image: {
            type: String,
            default: ''
        },
        id: {
            type: Number,
            default: null
        },
        labeldiscount: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        category: {
            type: String,
            default: ''
        },
        regularamount: {
            type: Number,
            default: ''
        },
        discountedamount: {
            type: Number,
            default: ''
        },
        labelnew: {
            type: Boolean,
            default: false
        },
        isVip: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LabelNew,
        LabelDiscount,
        AddToFavourites
    },
    mounted() {
    }
}