<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
    <div class="">
        <div class="container">
            <div class="row" v-if="userInfo">
                <div class="" v-if="$store.state.user.info.type === 'vendor'">
                    <VendorProfileSettings/>
                </div>
                <div class="" v-else>
                    <div class="add-property-title"> {{ $t('settings') }}</div>
                    <div class="row">
                        <div class="col-md-12 input-item">
                            <div class="input-title-item" v-if="$store.state.user.info.type === 'agency' && false"> {{
                                    $t('agency-name')
                                }}
                            </div>
                            <div class="input-title-item" v-if="$store.state.user.info.type !== 'vendor'"> {{
                                    $t('your-name')
                                }}
                            </div>
                            <div>
                                <InputField :inputtype="'text'" v-model="userInfo.name" :content="userInfo.name"
                                            :label="$t('enter-name')" :error="formErrors.includes('name')"/>
                            </div>
                        </div>
                        <div class="col-md-12 input-item">
                            <div class="input-title-item">Email</div>
                            <div>
                                <InputField :inputtype="'text'" v-model="userInfo.email" :content="userInfo.email"
                                            :label="$t('enter-email-placeholder')"
                                            :error="formErrors.includes('email')"/>
                            </div>
                            <div class="add-button" v-if="$store.state.user.info.type === 'agency' && false">
                                <Button :size="'small'" :theme="'solid-grey'" :text="$t('add-email-button')"
                                        :svg="'plus'"/>
                            </div>
                        </div>
                        <div class="col-md-12 input-item">
                            <div class="input-title-item"> {{ $t('contact-phone') }}</div>
                            <div>
                                <InputField :inputtype="'text'" v-model="userInfo.phone" :content="userInfo.phone"
                                            :label="$t('enter-phone-placeholder')"
                                            :error="formErrors.includes('phone')"/>
                            </div>
                            <div class="add-button" v-if="$store.state.user.info.type === 'agency' && false">
                                <Button :size="'small'" :theme="'solid-grey'" :text="$t('add-phone-button')"
                                        :svg="'plus'"/>
                            </div>
                        </div>
                        <div class="col-md-12 input-item" v-if="$store.state.user.info.type === 'agency' && false">
                            <div class="input-title-item"> {{ $t('profile-picture') }}</div>
                            <div class="">
                                <FileInput @change="e => uploadPhoto(e)"/>
                            </div>
                        </div>
                        <div class="col-md-12 input-item" v-if="$store.state.user.info.type === 'agency' && false">
                            <div class="input-title-item"> {{ $t('social-networks') }}</div>
                            <div class="input-item">
                                <div class="amenity-item">Facebook</div>
                                <InputField :inputtype="'url'" :label="$t('enter-link')"/>
                            </div>
                            <div class="input-item">
                                <div class="amenity-item">Instagram</div>
                                <InputField :inputtype="'url'" :label="$t('enter-link')"/>
                            </div>
                        </div>
                    </div>

                    <div class="mt-5 d-flex justify-content-center justify-content-md-start">
                        <div style="width: 200px">
                            <Button :theme="'solid-green'" :size="'medium'" :text="$t('save')" @click="save()"/>
                        </div>
                    </div>

                    <div class="input-item password-wrapper mt-5">
                        <div class="d-md-flex">
                            <div>
                                <div class="input-title-item"> {{ $t('change-password') }}</div>
                                <div class="input-description ">
                                    {{ $t('change-password-paragraph') }}
                                </div>
                            </div>
                            <div class="ms-auto">
                                <Button :size="'medium'" :theme="'solid-grey'" :text="$t('change-password')"
                                        @click="changePassword()"/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

