import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import Checkbox from "@/components/Common/Inputs/CheckBox/CheckBox";
import PropertyAd from "@/components/Components/PropertyAd/PropertyAd";
import {Carousel, Navigation, Pagination, Slide} from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'


export default {
    name: 'PremiumPropertyCarousel',
    props: {
        properties: {
            type: Array,
            default: []
        },
    },
    components: {
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew,
        Checkbox,
        PropertyAd,
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data: () => ({
        cities: null,
        selectedCity: null,
        filteredProperties: null,

        // carousel settings
        settings: {
            itemsToShow: 1.1,
            snapAlign: 'center',
        },
        // breakpoints are mobile first
        // any settings not specified will fallback to the carousel settings
        breakpoints: {
            // 700px and up
            700: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            // 1024 and up
            1024: {
                itemsToShow: 4.2,
                snapAlign: 'start',
            },
        },
    }),
    watch: {
        selectedCity(newValue, oldValue) {
            this.filterProperties();
        }
    },
    async mounted() {
        const cityIds = [1,3,6,4,23,7,5];
        const cities = await this.$store.dispatch("location/getCities");
        this.cities = cities.filter((city) => {
            return cityIds.includes(city.id)
        });
        this.cities = this.cities.sort((a, b) => cityIds.indexOf(a.id) - cityIds.indexOf(b.id));

        this.selectedCity = this.cities[0].id;
        this.filterProperties();
    },
    methods: {
        async filterProperties() {
            this.filteredProperties = [];
            let vm = this;
            this.$props.properties.forEach(property => {
                if (parseInt(property.city.id) === parseInt(vm.selectedCity)) {
                    this.filteredProperties.push(property);
                }
            });
        }
    }
}