<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div id="main-container" class="" v-if="vendor">
    <div class="container">
      <div class="d-flex align-items-center">
        <div class="vendor-image" v-bind:style="{'background-image': (vendor.profilePicture ? 'url(/storage/' + vendor.profilePicture + ')' : 'url(/static/user.png)')}"></div>
        <div class="vendor-name"> {{ vendor.name }} </div>
      </div>
      <div class="general-info">
        <div class="general-info-title">{{ $t('general-information') }}</div>
        <div v-html="vendor.shortDescription"></div>
      </div>
      <div class="company-details">
        <div class="company-detail-item" v-for="item in vendor.companyInfo">
          {{ item.key }}: {{ item.value }}
        </div>
      </div>
    </div>
    <div class=" bottom-container">
      <div class="company-terms container d-none d-md-block ">
        <div class="terms-navigation row ">
          <div class="terms-nav-item col-md-auto"
               @click="activeDescription = 'delivery'"
               :class="{ 'active-button': activeDescription === 'delivery' }"
          > {{ $t('delivery') }} </div>
          <div class="terms-nav-item col-md-auto"
               @click="activeDescription = 'warranty-conditions'"
               :class="{ 'active-button': activeDescription === 'warranty-conditions' }"
          > {{ $t('warranty-conditions') }} </div>
          <div class="terms-nav-item col-md-auto"
               @click="activeDescription = 'return-policy'"
               :class="{ 'active-button': activeDescription === 'return-policy' }"
          > {{ $t('return-policy') }} </div>
          <div class="terms-nav-item col-md-auto"
               @click="activeDescription = 'data-protection'"
               :class="{ 'active-button': activeDescription === 'data-protection' }"
          > {{ $t('data-protection') }} </div>
        </div>
        <div class="terms-description ">
          <div class="terms-description-item" v-if="activeDescription === 'delivery'" v-html="vendor.deliveryInfo"></div>
          <div class="terms-description-item" v-if="activeDescription === 'warranty-conditions'" v-html="vendor.warranty"></div>
          <div class="terms-description-item" v-if="activeDescription === 'return-policy'" v-html="vendor.returnPolicy"></div>
          <div class="terms-description-item" v-if="activeDescription === 'data-protection'" v-html="vendor.privacyPolicy"></div>
        </div>
      </div>
      <div class="company-terms container d-md-none" v-bind:class="{ 'open-terms-box': termsBoxActive }">
        <div class="terms-button-wrapper" >
          <div class="d-flex terms-button-mobile"  v-on:click="toggleTermsBox">
            <div class=" me-auto"> {{ $t('delivery') }} </div>
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.19843 12.8516C4.42269 13.0759 4.78629 13.0759 5.01055 12.8516L8.6651 9.19704C8.88937 8.97278 8.88937 8.60918 8.6651 8.38492C8.44084 8.16066 8.07724 8.16066 7.85298 8.38492L4.60449 11.6334L1.356 8.38492C1.13174 8.16066 0.768143 8.16066 0.543881 8.38492C0.31962 8.60918 0.31962 8.97278 0.543881 9.19704L4.19843 12.8516ZM4.03024 0.960388L4.03024 12.4455L5.17875 12.4455L5.17875 0.960388L4.03024 0.960388Z" fill="#1C1E2D"/>
            </svg>
          </div>
          <div class="terms-description-item"  v-html="vendor.deliveryInfo"> </div>
        </div>
        <div class="terms-button-wrapper" >
          <div class="d-flex terms-button-mobile"  v-on:click="toggleTermsBox">
            <div class=" me-auto"> {{ $t('warranty-conditions') }} </div>
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.19843 12.8516C4.42269 13.0759 4.78629 13.0759 5.01055 12.8516L8.6651 9.19704C8.88937 8.97278 8.88937 8.60918 8.6651 8.38492C8.44084 8.16066 8.07724 8.16066 7.85298 8.38492L4.60449 11.6334L1.356 8.38492C1.13174 8.16066 0.768143 8.16066 0.543881 8.38492C0.31962 8.60918 0.31962 8.97278 0.543881 9.19704L4.19843 12.8516ZM4.03024 0.960388L4.03024 12.4455L5.17875 12.4455L5.17875 0.960388L4.03024 0.960388Z" fill="#1C1E2D"/>
            </svg>
          </div>
          <div class="terms-description-item" v-html="vendor.warranty"></div>
        </div>
        <div class="terms-button-wrapper" >
          <div class="d-flex terms-button-mobile"  v-on:click="toggleTermsBox">
            <div class=" me-auto"> {{ $t('return-policy') }} </div>
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.19843 12.8516C4.42269 13.0759 4.78629 13.0759 5.01055 12.8516L8.6651 9.19704C8.88937 8.97278 8.88937 8.60918 8.6651 8.38492C8.44084 8.16066 8.07724 8.16066 7.85298 8.38492L4.60449 11.6334L1.356 8.38492C1.13174 8.16066 0.768143 8.16066 0.543881 8.38492C0.31962 8.60918 0.31962 8.97278 0.543881 9.19704L4.19843 12.8516ZM4.03024 0.960388L4.03024 12.4455L5.17875 12.4455L5.17875 0.960388L4.03024 0.960388Z" fill="#1C1E2D"/>
            </svg>
          </div>
          <div class="terms-description-item" v-html="vendor.returnPolicy"> </div>
        </div>
        <div class="terms-button-wrapper" >
          <div class="d-flex terms-button-mobile"  v-on:click="toggleTermsBox">
            <div class=" me-auto"> {{ $t('data-protection')}} </div>
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.19843 12.8516C4.42269 13.0759 4.78629 13.0759 5.01055 12.8516L8.6651 9.19704C8.88937 8.97278 8.88937 8.60918 8.6651 8.38492C8.44084 8.16066 8.07724 8.16066 7.85298 8.38492L4.60449 11.6334L1.356 8.38492C1.13174 8.16066 0.768143 8.16066 0.543881 8.38492C0.31962 8.60918 0.31962 8.97278 0.543881 9.19704L4.19843 12.8516ZM4.03024 0.960388L4.03024 12.4455L5.17875 12.4455L5.17875 0.960388L4.03024 0.960388Z" fill="#1C1E2D"/>
            </svg>
          </div>
          <div class="terms-description-item" v-html="vendor.privacyPolicy"> </div>
        </div>
      </div>
    </div>

  </div>
</template>

