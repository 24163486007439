import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import Checkbox from "@/components/Common/Inputs/CheckBox/CheckBox";

import RoomsOverlay from "@/components/Components/MarketplaceRoomsModal/Paths/RoomsOverlay.vue";
import LightsIcon from "@/components/Common/Icons/LightsIcon.vue";

export default {
    name: 'MarketplaceRoomsModal',
    props: {
        resultsamount: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        phone: {
            type: String,
            default: ''
        },
        email: {
            type: String,
            default: ''
        },
        date: {
            type: String,
            default: ''
        },
        time: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        propertyid: {
            type: String,
            default: ''
        },

    },
    components: {
        LightsIcon,
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew,
        Checkbox,
        RoomsOverlay

    },
    watch: {
        propertyLocation(newPropertyLocation, old) {
            let vm = this;
            this.districts = {};
            this.cities.forEach((city) => {
                if (parseInt(city.id) === parseInt(newPropertyLocation)) {
                    city.district.forEach((item) => {
                        vm.districts[item.name] = item.id;
                    });
                }
            });
        }
    },
    data() {
        return {
            cities: {},
            citiesOptions: [],
            districts: {},
            propertyLocation: null,
            propertyDistrict: null,
            hallwayURL:'static/room-hover-svgs/corridor.svg',
            isHovered: true
        }
    },
    async mounted() {
        await this.$store.dispatch("location/getCities");
        this.cities = this.$store.state.location.cities ?? null;
        this.citiesOptions = this.$store.state.location.citiesOptions ?? null;
    },
    methods: {
        redirectTo(route, params = {}, query = {}) {
            this.$store.dispatch("utils/toggleMarketplaceRoomsModal");
            this.$router.push({'name': route, params: params, query: query})
        }
    }
}